import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FoliosData } from '../../common/Data';
import PortfolioMod from '../../components/PortfolioMod';

const PortfolioDetailsStyle = styled("section")(()=> ({
    minHeight:"65vh",
    // width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"white",
    // backgroundColor:"royalblue!"

}))
const PortfolioDetailsContent = styled("div")(()=> ({
    minHeight:"75vh",
    width:"80%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"royalblue"
}))
const PortfolioDetailsLeft = styled("div")(()=> ({
    minHeight:"75vh",
    width:"65%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"pink"
}))
const PortfolioDetailsRight = styled("div")(()=> ({
    minHeight:"75vh",
    width:"35%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"flex-start",
    // backgroundColor:"green"
}))

const SwipeContent = styled("div")(()=> ({
    height:"50vh",
    width:"85%",
    // display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"red"
}))
const SwipeImg = styled("img")(()=> ({
    height:"50vh",
    width:"100%",
    objectFit:"cover"

}))
const FolioName = styled(Typography)(()=> ({
    fontFamily:"PoppinsBold, sans-serif !important",
    fontSize:"1.5em",


}))
const FolioDesc = styled(Typography)(()=> ({
    fontFamily:"PoppinsRegular, sans-serif !important",
    marginTop:"3vh",
    marginBottom:"8vh"


}))
const FolioLib = styled(Typography)(()=> ({
    fontFamily:"PoppinsRegular, sans-serif !important",

    // height:"50vh",
    // width:"100%",

}))
const FolioVal = styled(Typography)(()=> ({
    fontFamily:"PoppinsLight, sans-serif !important",

    // height:"50vh",
    // width:"100%",

}))
const FolioInfo = styled("div")(()=> ({
    // height:"50vh",
    // width:"100%",

}))
const OneInfo = styled(Stack)(()=> ({
    marginBottom:"10px"
    // height:"50vh",
    // width:"100%",

}))

interface Props {
    swipImage: string[],
    projectName: string,
    description: string,
    tech: string,
    typeProject:string,
    date: string,
    lien:string
}

function PortfolioDetails() {
    return (
        <PortfolioDetailsStyle>
            {
                FoliosData.map((oneFolio, index) => <PortfolioMod lien={oneFolio.link} swipImage={oneFolio.swipImgs} projectName={oneFolio.pojectTitre} description={oneFolio.projectDesc} tech={oneFolio.technologie} typeProject={oneFolio.projectType} date={oneFolio.dateProject} sx={{flexDirection: index % 2 === 0 ?  "row" : "row-reverse" }}/>)
            }
        </PortfolioDetailsStyle>
    );
}

export default PortfolioDetails;