import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as usersMails from "../../services/firestore/usersMails";
import { UserMailModel } from "../../models/UserMailModel";

export const addUserMail = createAsyncThunk<string, {oneAddUserMail: UserMailModel}>(
    "usersMails/addUserMail",
    async (args) => {
        const {oneAddUserMail} = args;
        return await usersMails.addUserMail(oneAddUserMail);
    }
);

interface UserMailState {
    usersMails: Array<UserMailModel>
};

const initialState: UserMailState = {
    usersMails: [],
};

const UserMailSlice = createSlice({
    name: "usersMails",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

    }
});

export default UserMailSlice.reducer