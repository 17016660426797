import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, Input, Stack, styled, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Logo from '../Logo';
import FooterItems from './FooterItems';
import FooterStyle from '../../Styles/FooterStyle';
import SocialMediaMenu from '../Header/SocialMediaMenu';
import TextButton from '../TextButton';
import ImageComponent from '../ImageComponent';
import { Adjust, Email, PhoneAndroid, Place } from '@mui/icons-material';
import ButtonStyled from '../ButtonStyled';
import { USER_PAGES } from '../../routes/path';
import FooterItemAd from './FooterItemAd';
import { UserMailModel } from '../../models/UserMailModel';
import { useAppDispatch } from '../../redux/store';
import { addUserMail } from '../../redux/slices/UsersMails';

const FooterContainerCopirght=styled(FooterStyle)(()=>({
    // minHeight: "250px",
    display: "flex",
    width:"calc(100% - 20px)",
    flexDirection: "column",
    // justifyContent: "space-around",
    alignItems:"center",
    backgroundColor: "#1B1919",
    // backgroundColor: "#010101",
    // backgroundColor: "coral",
    paddingTop:"55px",
    // bottom:"0px",
}));

const FooterContainer=styled("div")(({theme})=>({
    minHeight: "350px",
    width:"70vw",
    [theme.breakpoints.down('md')]: {
        // flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        width:"95%",
        // backgroundColor:"blue",
        },
}));

const FooterContainerPart=styled("div")(({theme})=>({
    minHeight:"175px",
    display:"flex",
    flexDirection:"column",
    // backgroundColor:"blue",
    padding:"20px",
    // width:"400px",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("md")]: {
        // width:"20%"
    },
}));
const FootertText=styled(Typography)(({theme})=>({
    width:"80%",
    color:"#7B7B80",
    fontFamily:"PoppinsRegular, sans-serif !important"

}));
const FootertPartTitle=styled(Typography)(({theme})=>({
    fontSize:"18px", 
    fontWeight:"bold",
    color:"white",
    fontFamily:"PoppinsBold, sans-serif !important"
}));
const LittleText = styled(Typography)(({theme})=>({
    fontSize:"18px", 
    color:"white",
    fontFamily:"PoppinsRegular, sans-serif !important",
    marginTop:"20px",
    marginBottom:"20px"
    
}));
const RightReservedStyle = styled("div")(({theme})=>({
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    width:"70vw",
    height:"75px",
    [theme.breakpoints.down("sm")]: {
        width:"90%",
    },
    
}));
const SouscriptButton = styled (Button)(()=>({
    padding: '0.8em 1.8em',
    // width:"150px",
    border: '2px solid white',
    borderRadius:"5px",
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    transition: '.3s',
    zIndex: 1,
    fontFamily: 'inherit',
    color: 'black',
    marginTop:"10px",
    '&::before': {
        content: '""',
        width: 0,
        height: '410%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        background: 'black',
        transition: '.5s ease',
        display: 'block',
        zIndex: -1,
      },
      '&:hover::before': {
        width: '105%',
      },
      '&:hover': {
        color: 'white',
      },
}))

const ButtonText = styled(Typography)(()=>({
  fontFamily:"PoppinsRegular, sans-serif !important",
  transition:"0.3s",
  '&:hover': {
    },
  }))

const TextFieldStyle=styled(TextField)(({theme})=>({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white', // couleur personnalisée
        },
        '&:hover fieldset': {
            borderColor: 'grey', // couleur au survol
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', //  couleur au clic/focus
          },
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'white', //  couleur personnalisée après le clic/focus
      },
      '& .MuiInputLabel-root': {
        color: 'blue', // Couleur du texte
      },
      "& .MuiInputBase-root": {
        color: 'white'
    }
   
}));

const DialogTextStyle = styled(DialogContentText)(()=> ({
    fontFamily:"PoppinsRegular, sans-serif !important",

}))

function Footer() {
    const [data, setData]= React.useState<UserMailModel>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const dispatch = useAppDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData((prev) => ({...prev, [e.target.name]: e.target.value} as UserMailModel));
        console.log(data);
      };

      const dialogClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setDialogIsOpen(false);
      };

      const resetForm = () => {
        setData({
          email: '',
        //   uid:'',
        });
      };

      const validate = (event: React.FormEvent) => {
        event.preventDefault();

        if (data) {
            dispatch(addUserMail({oneAddUserMail: data})).unwrap().then(() => {
                          setDialogIsOpen(true);
                          resetForm();
                          console.log(data);
            })
        }
      }

    return (
        <FooterContainerCopirght>
            <FooterContainer>
            <Grid container>
                <Grid item xs={12} md={6} sm={6} lg={2} sx={{display:"grid", placeItems:"start",}}>
                    <FooterContainerPart >
                        <Stack direction={"column"} rowGap={2}>
                            <Logo sx={{ width:'100px', height:"100px"}}/> 
                            <ImageComponent image={'/assets/images/logoK.png'} sx={{width:"80%", height:"80%"}}/>
                        </Stack>
                    </FooterContainerPart>
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={3} sx={{display:"grid", placeItems:"start",}}>
                    <FooterContainerPart >
                        <FootertPartTitle sx={{fontSize:"18px", fontWeight:"bold"}}>Liens Utiles</FootertPartTitle>
                        <FooterItems title={'Qui sommes-nous'} path={USER_PAGES.about} children={<Adjust sx={{color:"white"}} fontSize='small'/>}></FooterItems>
                        <FooterItems title={'Nous contacter'} path={USER_PAGES.contact} children={<Adjust sx={{color:"white"}} fontSize='small'/>}></FooterItems>
                        <FooterItems title={'Nos Réalisations'} path={USER_PAGES.portfolio} children={<Adjust sx={{color:"white"}} fontSize='small'/>}></FooterItems>
                        <FooterItems title={'Politique de Confidentialité'} path={USER_PAGES.portfolio} children={<Adjust sx={{color:"white"}} fontSize='small'/>}></FooterItems>
                        <FooterItems title={'Mentions Légales'} path={USER_PAGES.portfolio} children={<Adjust sx={{color:"white"}} fontSize='small'/>}></FooterItems>
                    </FooterContainerPart >
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={4} sx={{display:"grid", placeItems:"start"}}>
                    <FooterContainerPart>
                        <FootertPartTitle sx={{fontSize:"18px", fontWeight:"bold"}}>Contact</FootertPartTitle>
                        <FooterItemAd title={'Attiégougan, 10 BP:10172 Lomé-TOGO'} path={'https://goo.gl/maps/jfrj92W4jwq2Hbnp9'} children={<Place sx={{color:"white"}} fontSize='small'/>}></FooterItemAd> 
                        <FooterItemAd title={"+228 22 60 12 00"} path={'tel:+228  22601200'} children={<PhoneAndroid sx={{color:"white"}} fontSize='small'/>}></FooterItemAd>
                        <FooterItemAd title={"contact@kodinart.com"} path={'mailto:contact@kodinart.com'} children={<Email sx={{color:"white"}} fontSize='small'/>}></FooterItemAd>
                    </FooterContainerPart>
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={3} sx={{display:"grid", placeItems:"flex-start"}}>
                    <Box component={"form"} onSubmit={validate}>
                    <FooterContainerPart >
                        <LittleText>
                           Souscrivez à nos nouvelles
                        </LittleText>
                        <TextFieldStyle name='email' required={true} onChange={handleChange} value={data?.email} placeholder='Votre Email' type='email'></TextFieldStyle>
                        <SouscriptButton type='submit'><ButtonText>Souscrire</ButtonText></SouscriptButton>
                    </FooterContainerPart>
                    </Box>
                </Grid>
            </Grid>
            </FooterContainer>
            <Divider color={"grey"} sx={{width:"100%"}}/>
            <RightReservedStyle>
                <Typography sx={{display:"flex", flexDirection:"row", justifyContent:"center", color:"white"}}>Kodinart</Typography>
                <Typography sx={{display:"flex", flexDirection:"row", justifyContent:"center", color:"white"}}>©2023 Tous droits reservés</Typography>
            </RightReservedStyle>
            <Dialog open={dialogIsOpen} onClose={dialogClose}>
                    <DialogContent>
                        <DialogTextStyle>Merci d'avoir souscrit à nos nouvelles!</DialogTextStyle>
                        <DialogActions><Button onClick={dialogClose}>Fermer</Button></DialogActions>
                    </DialogContent>
                </Dialog>
        </FooterContainerCopirght>
    );
}

export default Footer;