import { Close } from '@mui/icons-material';
import { Box, Drawer as MuiDrawer, IconButton, styled, Divider, Typography, Chip, Stack, Button } from '@mui/material';
import React, { useState } from 'react';
import ImageComponent from '../ImageComponent';
import { UserMenuItems } from '../../common/MenuConfigs';
import MyDrawerItems from './MyDrawerItems';
import Fade from '../../animations/Fade';
import FadeVariants from '../../variants/FadeVariants';

const DrawerStyle = styled(MuiDrawer)((theme)=>({
    // backgroundColor:"green"
    // width:"500px",
    // flexShrink: 0, 
    // [`& .MuiDrawer-paper`]: 
    // { width: 500, 
    //   boxSizing: "border-box",
    //   position:"absolute",
    //   // marginRight:"10px",
    // },
}));

const BigBox = styled(Box)((theme)=>({
    backgroundColor:"red",
      
}));
const BoxContent = styled(Box)(({theme})=>({
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    height:"100%",
    // backgroundColor:"red",
    [theme.breakpoints.down('sm')]: {
        flexDirection:"column",
    },
}));
const MaskImage = styled("img")((theme)=>({
    maskMode:"luminance",
    maskSize:"cover",
    maskPosition:"center",
    maskRepeat:"no-repeat",
    opacity:0.3
}));
const Div = styled("div")((theme)=>({

}));
const Foot = styled("div")(({theme})=>({
    width:"calc(100% - 33px)",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"flex-start",
    paddingLeft:"25px",
    borderLeft:"8px solid green",
    // backgroundColor:"yellow",
    [theme.breakpoints.down('sm')]: {
        flexDirection:"column",
        borderLeft:"0px solid",
        paddingLeft:"0px",
        width:"100%",
        alignItems:"center",
        // backgroundColor:"red",
        borderTop:"8px solid green",
        height:"100%"



    },
}));
const Adress = styled("a")((theme)=>({
    textAlign:"center",
    fontFamily:"PoppinsRegular, sans-serif !important",
    color:"white",
    textDecoration:"none",
    '&:hover':{
        // color:"#C1BEBE",
        color:"#D7D2D2",
    }

}));
const LitteText = styled(Typography)(({theme})=>({
    textAlign:"center",
    fontFamily:"PoppinsRegular, sans-serif !important",
    color:"grey",
    margin:"10px 0px 15px 0px",
    [theme.breakpoints.down('sm')]: {
        margin:"5px 0px 8px 0px",
    },


}));

const Number = styled("a")(({theme})=>({
    margin:"10px 0px 10px 0px",
    textDecoration:"underline",
    fontSize:"1.5em",
    textAlign:"center",
    fontFamily:"PoppinsBold, sans-serif !important",
    color:"green",
    [theme.breakpoints.down('sm')]: {
        margin:"0px 0px 0px 0px",
    },
    '&:hover':{
        // color:"#C1BEBE",
        // color:"#D7D2D2"
    }


}));
const Mail = styled("a")(({theme})=>({
   margin:"10px 0px 10px 0px",
    textAlign:"center",
    fontFamily:"PoppinsRegular, sans-serif !important",
    color:"white",
    textDecoration:"none",
    [theme.breakpoints.down('sm')]: {
        margin:"0px 0px 0px 0px",
    },
    '&:hover':{
        // color:"#C1BEBE",
        color:"#D7D2D2",
    }


}));
const ReseauItem = styled("a")(({theme})=>({
    fontFamily:"PoppinsRegular, sans-serif !important",
    color:"white",
    textDecoration:"none",
    '&:hover':{
        // color:"#C1BEBE",
        color:"#D7D2D2",
    }


}));
const BigMenu = styled("div")(({theme})=>({
    // backgroundColor:"teal",
    backgroundColor:"#060606",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    height:"100%",
    width:"calc(35% - 1px)",
    borderRight:"1px solid #727071",
    [theme.breakpoints.down('md')]: {
        display:"none",
    },

}));

const BigMenuText = styled(Typography)(({theme})=>({
    fontSize:"14em",
    fontWeight:"bold",
    transform:"rotate(90deg)",
    color:"#727071",

}));

const MainMenu = styled("div")(({theme})=>({
    // backgroundColor:"aquamarine",
    backgroundColor:"#060606",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"flex-start",
    paddingLeft:"30px",
    height:"100%",
    // width:"30%",
    width:"calc(30% - 30px)",
    [theme.breakpoints.down('md')]: {
        width:"calc(50% - 30px)"
    },
    [theme.breakpoints.down('sm')]: {
        width:"100%",
        alignItems:"center",
        paddingLeft:"0px",

    },


}));
const InfoPart = styled("div")(({theme})=>({
    backgroundColor:"#060606",
    // backgroundColor:"chartreuse",
    width:"calc(35% - 1px)",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    height:"100%",
    borderLeft:"1px solid #727071",
    [theme.breakpoints.down('md')]: {
        width:"50%"
    },
    [theme.breakpoints.down('sm')]: {
        width:"100%",
        borderLeft:"0px solid ",
        // backgroundColor:"blue",
        height:"calc(28vh - 8px)",
    },
    [theme.breakpoints.down('xs')]: {
        width:"100%",
        borderLeft:"0px solid ",
        // backgroundColor:"blue",
        height:"calc(35vh - 8px)",
    },



}));
const StackStyle = styled(Stack)(({theme})=>({
    marginTop:"40px",
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
        marginTop:"8px",
        marginBottom:"10px"
    },



}));

interface Props {
    opening:boolean,
    closing: Function,
}
function MyDrawer({opening, closing}: Props) {

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleClose = () => {
        closing();
        setIsOverlayVisible(false);
      };
    return (
        <>
            {/* <BigBox sx={{display:  isOverlayVisible ? 'block' : 'none'}} onClick={() => setIsOverlayVisible(false)}> */}
            <DrawerStyle variant="temporary" anchor="top" open={opening} onClose={handleClose}>
            <Box sx={{ position: 'relative', height: '110vh', }}>
                        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
                            {/* <img src="https://example.com/image.jpg" alt="background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                            <MaskImage src='assets/images/blog.jpg' sx={{width: '100%', height: '100%', objectFit: 'cover', }}/>
                        </Box>
                        <BoxContent sx={{ position: 'relative', zIndex: 1}}>
                            {/* <IconButton sx={{ position: 'absolute', top: 8, right: 8 , zIndex: 1}} onClick={handleClose}>
                                <Close />
                            </IconButton> */}
                            <Button startIcon={<Close/>} sx={{ position: 'absolute', top: 8, right: 8 , zIndex: 1, color:"white"}} onClick={handleClose}>
                                Fermer
                            </Button>
                            <BigMenu>
                                <BigMenuText>MENU</BigMenuText>
                            </BigMenu>
                            <MainMenu>
                                {
                                    UserMenuItems.map((menu,index) => 
                                    {
                                        return <Fade variants={FadeVariants({durationIn: index * .3}).inRight} key={index}><Div onClick={handleClose}> <MyDrawerItems key={index} title={menu.title} path={menu.path} iconPath={''} /></Div></Fade>

                                    }
                                    )
                                }

                            </MainMenu>
                            <InfoPart>
                            <Foot>
                                {/* DFSFSFSDSDS */}
                                <LitteText>Prenez contact:</LitteText>
                                <Adress target='blank' href='https://goo.gl/maps/jfrj92W4jwq2Hbnp9'>Attiégougan, 10 BP 10172 Lomé Aviation-TOGO</Adress>
                                <Adress></Adress>
                                <Number target='_blank' href='tel:+228  22601200'>+228 22 60 12 00</Number>
                                <Mail target='_blank' href='mailto:contact@kodinart.com'>contact@kodinart.com</Mail>
                                <StackStyle direction={"row"} spacing={2} sx={{}}>
                                    <ReseauItem target='-blank' href='https://www.facebook.com/people/Kodinart/100093572302096/?notif_id=1687741602744143&notif_t=page_user_activity&ref=notif'>FaceBook</ReseauItem>
                                    <ReseauItem target='-blank' href='https://www.instagram.com/kodinart/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'>Instagram</ReseauItem>
                                    <ReseauItem target='-blank' href='https://wa.me/90066593'>WhatsApp</ReseauItem>
                                    <ReseauItem>LinkedIn</ReseauItem>
                                </StackStyle>
                            </Foot>
                            </InfoPart>
                        </BoxContent>
                    </Box>
                </DrawerStyle>
            {/* </BigBox> */}
        </>
    );
}

export default MyDrawer;