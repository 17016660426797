import { Card, Stack, Typography, styled } from '@mui/material';
import React from 'react';

const QualitieCardContainer = styled(Card)(({theme})=> ({
    width:"275px",
    // maxWidth:"100%",
    height:"225px",
    padding:"10px 10px 10px 10px",
    boxShadow:"none",
        [theme.breakpoints.down('sm')]: {
            width:"98%",
        },
}))
const QualitieNumStyle = styled(Typography)(({theme})=> ({
        lineHeight:"1.0em",
        fontSize:"2.0em",
        color:"transparent",
        WebkitTextStroke:"1px white",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",
        [theme.breakpoints.down('md')]: {
        
        },
}))
const QualitieNumContainerStyle = styled("div")(({theme})=> ({
    width:"65px",
    height:"65px",
    borderRadius:"10px",
    backgroundColor:"black",
    display:"grid",
    placeItems:"center",
        [theme.breakpoints.down('md')]: {
        
        },
}))
const QualitieTitleStyle = styled(Typography)(({theme})=> ({
    fontFamily:"PoppinsBold, sans-serif !important",

        [theme.breakpoints.down('md')]: {
        
        },
}))
const QualitieDescContaint = styled("div")(({theme})=> ({
//    display:"grid",
//    placeItems:"center",
//    backgroundColor:"yellow",
   height:"70%",
        [theme.breakpoints.down('md')]: {
        
        },
}))
const QualitieDescStyle = styled(Typography)(({theme})=> ({
    fontFamily:"PoppinsLight, sans-serif !important",
    textAlign:"justify",

        [theme.breakpoints.down('md')]: {
        
        },
}))

interface Props {
    num:string,
    title:string,
    desc:string,
}

function QualitieCard({num, title, desc}:Props) {
    return (
        <QualitieCardContainer>
            <Stack direction={'row'} spacing={2} sx={{ height:"30%", alignItems:"center", display:"flex"}}>
                <QualitieNumContainerStyle>
                    <QualitieNumStyle>{num}</QualitieNumStyle>
                </QualitieNumContainerStyle>
                <QualitieTitleStyle> {title}</QualitieTitleStyle>
            </Stack>
            <QualitieDescContaint>
                <QualitieDescStyle>
                    {desc}
               </QualitieDescStyle>
            </QualitieDescContaint>
        </QualitieCardContainer>
    );
}

export default QualitieCard;