import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import TextButton from './TextButton';
import { USER_PAGES } from '../routes/path';

const SectionTypeStyle = styled("section")(()=>({
    width:"100%",
    height:"45vh",
    // position:"absolute",
    backgroundImage: `url(/assets/images/4.png)`,
    backgroundSize:"cover",
    objectFit:"cover",
    backgroundPosition:"center",
    backgroundRepeat:"no-repeat",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // transition: '1s ease-out'
}))

const HeroTextOne = styled(Typography)(()=>({
    color:"white", 
    // marginLeft:"150px",
    letterSpacing:"5px",
    marginBottom:"5vh",
    fontSize:"2.0em",
    transition: '1s ease-out',
    fontFamily:"PoppinsBold, sans-serif !important",
}))
const HomeButton = styled(Typography)(()=>({
    color:"white", 
    marginLeft:"150px",
    letterSpacing:"5px",
    marginBottom:"5vh",
    fontSize:"2.0em",
    transition: '1s ease-out',
    fontFamily:"PoppinsBold, sans-serif !important",
}))
const CurrentPage = styled(Typography)(()=>({
    fontFamily:"PoppinsRegular, sans-serif !important", 
    color:'white'
}))
const Circle = styled(Typography)(()=>({
    fontFamily:"PoppinsRegular, sans-serif !important", 
    color:'white'
}))

interface Props {
    pageTitle:string,

}

function SectionType({pageTitle}: Props) {
    return (
        <SectionTypeStyle>
            <HeroTextOne>{pageTitle}</HeroTextOne>
            <Stack direction={"row"} sx={{}} spacing={1}>
                <TextButton title={'Accueil'} path={USER_PAGES.home} sx={{fontFamily:"PoppinsRegular, sans-serif !important", color:'white', '&:hover':{textDecoration:"underline"}}}/>
                <Circle>⚬</Circle>
                <CurrentPage> {pageTitle}</CurrentPage>
            </Stack>
        </SectionTypeStyle>
    );
}

export default SectionType;