import React from 'react';
import SectionType from '../components/SectionType';
import MapInfo from '../sections/ContactPage/MapInfo';
import FormSection from '../sections/ContactPage/FormSection';
import Page from '../components/Pages';
import MapSection from '../sections/ContactPage/MapSection';
import { Helmet } from 'react-helmet';

function Contact() {
    return (
            <Page  title={'Contact'}>                
                <Helmet>
                    <meta name="author" content="KODINART"/>
                    <meta name="description" content="Contactez-nous pour discuter de vos projets informatiques. Nous offrons des services de développement web, QA et test, et UI/UX Design."></meta>
                    <meta name="keywords" content="contact, devis, développement ,web , mobile, QA & test, UI/UX, Design"></meta>
                </Helmet>
                <SectionType pageTitle={'Contact'}/>
                <MapInfo/>
                <FormSection/>
                <MapSection/>
            </Page>
    );
}

export default Contact;