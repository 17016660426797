import { Box, Button, Card, Typography, styled } from '@mui/material';
import React from 'react';
import ImageComponent from './ImageComponent';
import { ArrowOutward, ArrowRightAlt, Launch } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { USER_PAGES } from '../routes/path';

const FolioItemStyle = styled(Card)(()=>({
    width:"90%",
    // width:"375px",
    height:"380px",
    // backgroundColor:"paleturquoise",
    // backgroundPosition: "center",
    // backgroundRepeat:"no-repeat",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"center",
    // alignItems:"center",
    transition: '.5s ease',
    borderRadius:"3px",
    position:"relative",
    overflow:"hidden",
    '&:hover': {
        opacity: 1,
    },
    }))
const FolioImgStyle = styled("img")(()=>({
    width:"100%",
    height:"100%",
    transition:"0.3s",
    objectFit:"cover",
    '&:hover': {
        transform:"scale(1.1)"
      },
    }))
const FolioInfo = styled("div")(()=>({
    width:"100%",
    height:"100%",
    position:"absolute",
    // color:"blue",
    background:"rgba(0, 0, 0, 0.8)",
    transition:"all ease-in-out 0.3s",
    zIndex:3,
    inset: 0,
    opacity:0,
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    '&:hover': {
        opacity: 1,
      },
    }))
const FolioName = styled(Typography)(()=>({
    color:"white",
    fontSize:"15px",
    fontFamily:"PoppinsBold, sans-serif !important",
    '&:hover': {
        opacity: 1,
      },
    }))
const FolioButton = styled(Button)(()=>({
    color:"white",
    fontSize:"15px",
    fontFamily:"PoppinsRegular, sans-serif !important",
    top:20,
    textTransform:"capitalize",
    '&:hover': {
        opacity: 1,
      },
    }))

    const DivButton = styled(Link)(()=>({
        textDecoration:"none",
        }))

    interface Props {
        projectName:string,
        coverImg:string,
    }

function FolioItem({coverImg,projectName}:Props) {
    return (
        <FolioItemStyle>
            {/* <ImageComponent image={'assets/images/folioOne.jpg'}/> */}
            <FolioInfo>
                <FolioName>{projectName}</FolioName>
                {/* <FolioButton endIcon={<ArrowRightAlt/>}>Voir Plus</FolioButton> */}
                {/* <FolioButton endIcon={<ArrowOutward/>}>Voir Plus</FolioButton> */}
                <DivButton to={USER_PAGES.portfolio}>
                    <FolioButton endIcon={<Launch/>}>Voir Plus</FolioButton>
                </DivButton>
            </FolioInfo>
            {/* <FolioImgStyle src='assets/images/goal.jpg'/> */}
            <FolioImgStyle src={coverImg}/>
            {/* <FolioImgStyle src='assets/images/P1.jpg'/> */}
        </FolioItemStyle>
    );
}

export default FolioItem;