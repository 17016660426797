import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import authReducer from "./slices/auth";
import commentreducer from "./slices/Comments";
import mailreducer from "./slices/Mails";
import userMailreducer from "./slices/UsersMails"

// import contratReducer from "./slices/Contrat";
// import devisReducer from "./slices/Devis";


export const store = configureStore({
    reducer: {
        comments: commentreducer,
        mails: mailreducer,
        usersMails: userMailreducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;