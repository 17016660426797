import { Box, Card, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';



const PjojectNumberItemStyle = styled(Box)(()=>({
    width:"275px",
    height:"200px",
    // backgroundColor:"paleturquoise",
    backgroundPosition: "center",
    backgroundRepeat:"no-repeat",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
    }))

const NumberStyle = styled(Typography)(()=>({
    color:"transparent",
    WebkitTextStroke:"1px black",
    WebkitTextFillColor:"transparent",
    // marginLeft:"150px",
    fontWeight:800,
    fontSize:"65px",
    // marginTop:"20px",
    // marginBottom:"40px"

    }))
const SubText = styled(Typography)(()=>({
    color:"black",
    fontSize:"1em",
    fontWeight:600,
    fontFamily:"PoppinsBold, sans-serif !important",


    }))

    const Counter = ({ targetNumber }: {targetNumber: string}) => {
        const [counter, setCounter] = useState(0);
        const counterRef = useRef<HTMLDivElement>(null);
        const isFirstRender = useRef(true);

        function isElementInViewport(element: HTMLElement) {
            const rect = element.getBoundingClientRect();
            return (
              rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
          }

      
        useEffect(() => {
            //   if (counterRef.current && isFirstRender.current) {
            //     gsap.to(counterRef.current, {
            //         duration: 3,
            //         ease: "power3.out",
            //         innerHTML: targetNumber,
            //         snap: "innerHTML",
            //         onUpdate: () => setCounter(Number(counterRef.current?.innerHTML)),
            //       });
            //       isFirstRender.current = false;
            //   }

            const handleScroll: EventListener = () => {
                if (
                  counterRef.current &&
                  isFirstRender.current &&
                  isElementInViewport(counterRef.current)
                ) {
                  gsap.to(counterRef.current, {
                    duration: 3,
                    ease: "power3.out",
                    innerHTML: targetNumber,
                    snap: "innerHTML",
                    onUpdate: () => setCounter(Number(counterRef.current?.innerHTML)),
                  });
                  isFirstRender.current = false;
                  window.removeEventListener("scroll", handleScroll);
                }
              };
            
              window.addEventListener("scroll", handleScroll);
            
              return () => {
                window.removeEventListener("scroll", handleScroll);
              };
        }, [targetNumber]);
      
        return <div ref={counterRef}>{counter}</div>;
      };

    interface Props {
        num: string,
        backImg:string,
        libelle:string,
    }

function PjojectNumberItem({num, backImg, libelle}: Props) {
    return (
        <PjojectNumberItemStyle sx={{ backgroundImage:"url('"+backImg+"')",}}>
            <NumberStyle>
                <Counter targetNumber={num}/>
            </NumberStyle>
            <SubText>
                {libelle}
            </SubText>
        </PjojectNumberItemStyle>
    );
}

export default PjojectNumberItem;