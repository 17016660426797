import { Divider, Grid, List, Typography, styled } from '@mui/material';
import React from 'react';
import AboutListItem from '../../components/AboutListItem';
import AboutStyledButton from '../../components/AboutStyledButton';
import QualitieCard from '../../components/QualitieCard';
import Fade from '../../animations/Fade';
import FadeVariants from '../../variants/FadeVariants';
import { Link } from 'react-router-dom';
import { USER_PAGES } from '../../routes/path';

const AboutDetailContainer = styled("section")(({theme})=> ({
    minHeight:"50vh",
    // backgroundColor: "blueviolet",
    overflow: "hidden",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // padding:"15px 0px 15px 0px",
    [theme.breakpoints.down('lg')]: {
        // fontSize:"1.1em",
        // flexDirection:"column",
        
    },
}))

export const SectionContaint = styled("div")(({theme})=> ({
    // minHeight:"80vh",
    padding:"10vh 0vh 8vh 0vh ",
    width:"70vw",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"red",
    [theme.breakpoints.down('lg')]: {
        // backgroundColor:"red",
        width:"95%",
        // padding:"15px 0px 15px 0px"
        },
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        // padding:"10px 0px 10px 0px"
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))

const AboutDetailsHeroLeft = styled("div")(({theme})=> ({
    width:"50%",
    // backgroundColor:"red",
    // minHeight:"70vh",
    display:'flex',
    flexDirection:"column",
    justifyContent:'center',
    alignItems:'flex-end',
    [theme.breakpoints.down('md')]: {
        width:"100%",
        minHeight:"60vh",
        // minHeight:"30vh",
        // backgroundColor:"yellow",
        },
    [theme.breakpoints.down('md')]: {
        width:"100%",
        minHeight:"30vh",
        // minHeight:"30vh",
        // backgroundColor:"yellow",
        },
}))
const AboutDetailsHeroRight = styled("div")(({theme})=> ({
    width:"50%",
    // position:"relative",
    // backgroundColor:"violet",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"flex-start",
    // minHeight:"70vh",
        [theme.breakpoints.down('md')]: {
        width:"100%",
        // backgroundColor:"violet",
        minHeight:"70vh",
        },
}))

const AboutTitle = styled(Typography)(()=> ({
    borderLeft:"2px solid black",
    padding:"3px",
    fontFamily:"PoppinsRegular, sans-serif !important"
 
 }))
const AboutTextFirst = styled(Typography)(({theme})=> ({
    lineHeight:"1.0em",
    fontSize:"2.5em",
    fontWeight:"bold",
    fontFamily:"PoppinsBold, sans-serif !important",
    '& .decorateText': {
        lineHeight:"1.0em",
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",
        [theme.breakpoints.down('lg')]: {
            // fontSize:"2.2em",
            lineHeight:1.2,
            // backgroundColor:"red"
            },

      },
      [theme.breakpoints.down('lg')]: {
        fontSize:"2.2em",
        lineHeight:1.2,
        },    
      [theme.breakpoints.down('md')]: {
        fontSize:"1.8em",
        },


}))

 const AboutTextDesc = styled(Typography)(({theme})=> ({
     width:"90%",
     marginTop:"15px",
     fontSize:"0.9em",
    //  color:"red",
     fontFamily:"PoppinsRegular, sans-serif !important",
     [theme.breakpoints.down('sm')]: {
        fontSize:"0.9em",
        },
 
 }))

const AboutDetailImgOne = styled("img")(({theme})=> ({
    // height:"50vh", 
    scale:"1.2",
    width:"75%", 
    borderRadius:"10px",
    // transition:"O.3s ease-in-out",
    transition:"all 0.3s ease",
        '&:hover': {
        // WebkitTransform:"scale(1.3)",
        transform:"scale(1.02)",
        // transition:"O.3s ease-in-out",

      },

      [theme.breakpoints.down('lg')]: {
        scale:"1.2",
        },
      [theme.breakpoints.down('md')]: {
        width:"95%",
        scale:"1",
        },
}))

const ListeStyle = styled(List)(({theme})=> ({
    marginTop:"12px", 
    marginBottom:"12px",
    [theme.breakpoints.down('lg')]: {
        marginTop:"10px", 
        marginBottom:"10px",
    },

}))

const DivButton = styled(Link)(()=>({
    textDecoration:"none",
    }))


function AboutDetailsSection() {
    return (
        <AboutDetailContainer>
            <SectionContaint>
                <AboutDetailsHeroLeft>
                    <Grid container >  
                        <Grid item xs={12} sx={{display:"grid", placeItems:"center"}}>
                        <Fade  variants={FadeVariants({durationIn:1}).inLeft}>
                            {/* <AboutDetailImgOne src='assets/images/abt.jpg'/> */}
                            <AboutDetailImgOne src='assets/images/12.jpg'/>
                        </Fade>
                        </Grid>
                    </Grid>
                </AboutDetailsHeroLeft>
                <AboutDetailsHeroRight>
                <AboutTitle>
                        A Propos De Nous
                    </AboutTitle>
                    <AboutTextFirst>
                        Des capacités illimitées pour des<Typography className='decorateText' component={"span"}>projets extraordinaires </Typography>
                    </AboutTextFirst>
                    {/* <AboutTextDesc>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum consectetur quia ipsam impedit sapiente cum officia velit, aliquam nulla? Aspernatur doloremque nesciunt velit quisquam labore rerum nihil. Voluptatem assumenda ratione id dolor unde adipisci nulla esse odit. Quis, beatae esse!
                    </AboutTextDesc> */}
                    <AboutTextDesc>
                    Nous sommes une agence spécialisée dans les tests logiciels et dans le développement web. Tous les jours nous aidons nos clients dans tout le monde entier à améliorer leur présence ou à s’affirmer sur le net. Nous sommes également des professionnels du marketing digital et du référencement. Nous créons des expériences exceptionnelles et transformons les idées en réalité.
                    </AboutTextDesc>
                    <AboutTextDesc>
                    Nous avons su faire  notre place parmi les entreprises des services numeriques specialisés dans les tests logiciels.
                    </AboutTextDesc>
                    {/* <List sx={{marginTop:"15px", marginBottom:"15px"}}> */}
                    <ListeStyle sx={{}}>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <AboutListItem text={"Expertise"}/>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <AboutListItem text={"Ouverture"}/>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <AboutListItem text={"Accompagnement"}/>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <AboutListItem text={"Responsabilité"}/>
                            </Grid>
                        </Grid>
                    </ListeStyle>
                    <DivButton to={USER_PAGES.contact}>
                    <AboutStyledButton content={'Contact'}/>
                    </DivButton>
                </AboutDetailsHeroRight>

            </SectionContaint>
            <Divider sx={{width:'70vw'}}/>

        </AboutDetailContainer>
    );
}

export default AboutDetailsSection;