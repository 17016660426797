import { Card, CardContent, CardMedia, Typography, styled } from '@mui/material';
import React from 'react';
import Fade from '../animations/Fade';
import FadeVariants from '../variants/FadeVariants';

const TeamMemberImgStyle = styled(Card)(() => ({
    width:"100%",
    height:"100%",
    objectFit:"cover",
    // position:"absolute",
    transition:"transform .3s ease-in-out",
    zIndex:2,
    '&:hover': {
    },

}));
const CardMemberStyle= styled(Card)(({theme}) => ({
  maxWidth: 300, 
  borderRadius:"8px 8px 0px 0px",
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%", 
},
    '&:hover': {
    },

}));

const TeamMemberName = styled(Typography)(() => ({
    fontFamily:"PoppinsBold, sans-serif !important",
    color:"white",
    // opacity:0,
    '&:hover': {
        // opacity:1,
    },
}));
const TeamMemberPost = styled(Typography)(() => ({
    fontFamily:"PoppinsLight, sans-serif !important",
    color:"white",
    '&:hover': {
    },
}));


interface Props {
  image:string,
  nom: string,
  post: string,
}
function OneMember({image, nom, post}: Props) {
    return (
      <Fade  variants={FadeVariants({durationIn:3}).in}>
        <CardMemberStyle sx={{  }}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="350"
          image={image}
        />
        <CardContent sx={{padding:"10px 0px 0px 0px", backgroundColor:"black"}}>
          <TeamMemberName gutterBottom variant="h5" >
            {nom}
          </TeamMemberName>
          <TeamMemberPost variant="body2" >
            {post}
          </TeamMemberPost>
        </CardContent>
      </CardMemberStyle>
      </Fade>
    );
}

export default OneMember;