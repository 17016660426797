import { ListItem, Typography, styled } from '@mui/material';
import React from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';


const CustomLink = styled(NavLink)(() => ({
    width: "calc(100%)",
    height: "100%",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",
    textDecoration: "none",

    fontSize: 20,
    // fontWeight:"bold",
    color: "black",
    "&:hover": {
        // backgroundColor:"grey",
        // borderRight: "3px solid #78BAFE",
    },
}));

const LinkText = styled(Typography)(({theme}) => ({
    fontSize:"2.5em",
    fontWeight:"bold",
    display:"flex",
    color:"white",
    letterSpacing:3,
    fontFamily:"YsabeauBold, sans-serif !important",
    transition:"2s ease-all",
    alignItems:"center",
     "&:hover": {
        color:"transparent",
        WebkitTextStroke:"1px #F1F1F1",
        WebkitTextFillColor:"transparent",
        fontFamily:"YsabeauBold, sans-serif !important",
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"1.8em",
        },
 }));

 interface Props {
    title: string,
    path: string,
    iconPath:string,
}

function MyDrawerItems({title, path, iconPath}: Props) {

    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <ListItem key={title} disablePadding sx={{}}>
        <CustomLink to={path} sx={{height: "70px"}} style={{borderBottom: match ? "grey" : ""}}>
            <LinkText style={{borderBottom: match ? "2px solid white" : ""}}>{title}</LinkText>
        </CustomLink>
    </ListItem>
    );
}

export default MyDrawerItems;