import { Divider, List, ListItemText, Typography, styled } from '@mui/material';
import React from 'react';
import { PROJECT_COLORS } from '../common/colors';
import PolicyListItem from './PolicyListItem';
import { FiberManualRecord } from '@mui/icons-material';

const MentionsTextStyle=styled("div")(()=>({
}))
const ParagraphText = styled(Typography)(()=>({
    fontSize:"1.0em",
    fontFamily:"PoppinsRegular, sans-serif !important",
    textAlign:"justify",
}))
const ParagraphTitle = styled(Typography)(()=>({
    color: PROJECT_COLORS.primarySwatch,
    fontFamily:"NunitoRegular, sans-serif !important",
    fontSize:"1.5em",
    fontWeight:"bold",
    margin:"0px 0px 10px 0px",

}))
const ParagraphSubTitle = styled(Typography)(()=>({
    color: PROJECT_COLORS.primarySwatch,
    fontFamily:"NunitoRegular, sans-serif !important",
    fontSize:"1.5em",
    fontWeight:"bold",
    margin:"10px 0px 10px 20px",

}))
const MainTitle = styled(Typography)(()=>({
    color: PROJECT_COLORS.primarySwatch,
    fontFamily:"NunitoBold, sans-serif !important",
    fontSize:"2.0em",
    fontWeight:"bold",
    textAlign:"center",
    margin:"0px 0px 20px 0px"
}))
const ParagraphContainer = styled("div")(()=>({
    margin:"0px 0px 25px 0px"
}))
const ParagraphSubContainer = styled("div")(()=>({
    margin:"0px 0px 25px 0px",
    padding:"0px 0px 0px 25px"
}))
const ListItemTextStyle = styled(ListItemText)(()=>({
    fontSize:"1.0em",
    fontFamily:"PoppinsRegular, sans-serif !important",
}))
function MentionsText() {
    return (
        <MentionsTextStyle>
            <MainTitle>Mentions légales Kodinart</MainTitle>
            <ParagraphContainer>
                <ParagraphText>Il est porté à la connaissance des Utilisateurs du site kodinart.com les présentes mentions légales.</ParagraphText>
                <ParagraphText>La connexion et la navigation sur le site kodinart.com par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</ParagraphText>
                <ParagraphText>Ces dernières sont accessibles sur le site à la rubrique « Mentions légales ».</ParagraphText>
            </ParagraphContainer>
            <ParagraphContainer>
             <ParagraphTitle>ARTICLE 1 : L’éditeur</ParagraphTitle>
             <ParagraphText>L'édition du site kodinart.com est assurée par l’entreprise kodinart.com, immatriculée au Centre de Formalités des Entreprises de Lomé et dont le siège social est situé à Attiégou, Numéro de téléphone<span><a style={{ textDecoration:"none", color:"green"}} href='tel:+228 22 60 12 00'>+228 22 60 12 00</a></span>, adresse e-mail : <span><a style={{ textDecoration:"none", color:"green"}} href='mailto:contact@kodinart.com'>contact@kodinart.com</a></span>.</ParagraphText>
             <ParagraphText>Le Directeur de la publication est : <span style={{ fontWeight:"bold"}}>Kokou Abalo</span></ParagraphText>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphTitle>ARTICLE 2 : L’hébergeur</ParagraphTitle>
                <ParagraphText>Le site kodinart.com est hébergé chez le fournisseur Hostinger.</ParagraphText>
                <ParagraphText>Pour plus d’informations, veuillez-vous référer au lien suivant : La confidentialité de Hostinger<span><a style={{ textDecoration:"none", color:"green"}} href='https://www.hostinger.fr/politique-de-confidentialite'>(https://www.hostinger.fr/politique-de-confidentialite)</a></span></ParagraphText>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphTitle>ARTICLE 3 : Accès au site</ParagraphTitle>
                <ParagraphText>Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découler d’une nécessité de maintenance.</ParagraphText>
                <ParagraphText>En cas de modification, interruption ou suspension des services le site kodinart.com ne saurait être tenu responsable.</ParagraphText>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphTitle>ARTICLE 4 : Politique de Protection des données</ParagraphTitle>
                <ParagraphText>La présente Politique de protection des données à caractère personnel a pour objectif de définir les principes et lignes directrices mis en œuvre par kodinart.com en matière de traitement des données à caractère personnel des utilisateurs du site internet kodinart et des utilisateurs de ses services.</ParagraphText>
                    <ParagraphSubContainer>
                        <ParagraphContainer>
                            <ParagraphSubTitle>1-	Données collectées par le site kodinart.com</ParagraphSubTitle>
                            <ParagraphText>Il s’agit des données que vous fournissez lorsque vous complétez un formulaire de contact ou le formulaire de candidature à nos offres d'emploi :</ParagraphText>
                            <List>
                                <PolicyListItem text={'Votre Nom et Prénom'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Votre Email'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Votre Téléphone'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Votre Entreprise'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Votre CV'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                            </List>
                        </ParagraphContainer>
                        <ParagraphContainer>
                            <ParagraphSubTitle>2-	Données de connexion collectées indirectement</ParagraphSubTitle>
                            <ParagraphText>Il s’agit des données que kodinart collecte à partir de votre navigation sur le site internet kodinart.com telles que :</ParagraphText>
                            <List>
                                <PolicyListItem text={'Les dates et heures de connexion'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Le type de votre navigateur'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Votre adresse IP'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Les pages que vous avez visitées'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={"Les mesures d'audience (statistiques liées aux emailing, ouverture des emails, clics...)"} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                            </List>
                            <ParagraphText>Le traitement de ces données de connexion se fait dans le respect de la réglementation applicable en matière de protection des données à caractère personnel. L’analyse de ces données a pour but :</ParagraphText>
                            <List>
                                <PolicyListItem text={'de produire des indicateurs non nominatifs et de vous proposer un service plus personnalisé sur le site internet'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'de s’assurer que l’utilisation du site est conforme aux conditions générales d’utilisation.'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                            </List>
                        </ParagraphContainer>
                        <ParagraphContainer>
                            <ParagraphSubTitle>3-	Utilisation des données collectées</ParagraphSubTitle>
                            <ParagraphText>Kodinart est responsable des traitements de données à caractère personnel que vous lui avez confiées pour les finalités suivantes :</ParagraphText>
                            <List>
                                <PolicyListItem text={'Souscription à la newsletter'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={"Communications à titre informatif sur les événements organisés, les nouvelles publications disponibles et nos offres d'emploi."} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={"Suivi et analyse de la qualité des services rendus pour améliorer ces services"} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={"Pour vous recontacter si vous en avez soumis la demande par l'un des formulaires du site kouka.io"} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={"Destinataires des données à caractère personnel"} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                            </List>
                            <ParagraphText>Toutes les données à caractère personnel étant confidentielles, leur accès est limité aux services de Kodinart ayant un intérêt réel à les utiliser pour exécuter les services rendus aux utilisateurs (services marketing, commercial et RH).</ParagraphText>
                            <ParagraphText>Quoi qu’il en soit toutes les personnes ayant accès à vos données à caractère personnel sont tenues à une obligation de confidentialité et au respect de la présente politique de protection des données.</ParagraphText>
                            <ParagraphText>En remplissant l'un des formulaires sur le site kodinart.com, vous consentez à partager vos données à caractère personnel avec les services de Kodinart.</ParagraphText>
                            <ParagraphContainer>
                                <ParagraphSubTitle>Durée de la conservation de vos données à caractère personnel</ParagraphSubTitle>
                                <ParagraphText>Toutes les données collectées sur le site internet kodinart.com sont conservées conformément aux durées légales et réglementaires :</ParagraphText>
                                <List>
                                    <PolicyListItem text={'Prospection commerciale : 2 ans'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                    <PolicyListItem text={'Envoi de sollicitations (e-mailing, appels, etc) : 2 ans'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                    <PolicyListItem text={'Statistiques de mesures d’audience : 6 mois'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                    <PolicyListItem text={'Recrutement : 2 ans'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                </List>
                                <ParagraphText>Les données sont supprimées dans les meilleurs délais à compter de la demande de suppression.</ParagraphText>
                            </ParagraphContainer>
                        </ParagraphContainer>
                        <ParagraphContainer>
                            <ParagraphSubTitle>4-	Sécurité de vos données à caractère personnel</ParagraphSubTitle>
                            <ParagraphText>Kodinart s’attache à conserver les données à caractère personnel qu’elle traite de manière sécurisée et uniquement pendant la durée nécessaire à la réalisation de la finalité poursuivie par le traitement.</ParagraphText>
                            <ParagraphText>Kodinart prend ainsi toutes les mesures physiques, techniques et organisationnelles afin de prévenir et d’empêcher, dans la mesure du possible, toute altération, perte des données ou accès non autorisé à celles-ci.</ParagraphText>
                        </ParagraphContainer>
                        <ParagraphContainer>
                            <ParagraphSubTitle>5-	Stockage de vos données à caractère personnel</ParagraphSubTitle>
                            <ParagraphText>Les données à caractère personnel sont stockées soit dans les bases de données de Kodinart, soit dans celles de prestataires de services avec lesquels Kodinart a une relation contractuelle.</ParagraphText>
                            <ParagraphText>Dans le deuxième cas, Kodinart s’assure que les prestataires externes soient en conformité avec le RGPD.</ParagraphText>
                        </ParagraphContainer>
                        <ParagraphContainer>
                            <ParagraphSubTitle>6-	Droits relatifs aux données à caractère personnel</ParagraphSubTitle>
                            <ParagraphText>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. Vous pourrez exercer votre droit par l'un des moyens suivants :</ParagraphText>
                            <List>
                                <PolicyListItem text={"Par mail à l'adresse mail : privacy@kodinart.com"} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Par voie postale au : Attiégougan, 10 BP 10172 Lomé Aviation-TOGO'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                                <PolicyListItem text={'Via le formulaire de contact'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                            </List>
                        </ParagraphContainer>
                    </ParagraphSubContainer>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphTitle>ARTICLE 5 : Cookies</ParagraphTitle>
                <ParagraphText>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.</ParagraphText>
                <ParagraphText>En naviguant sur le site, il les accepte.</ParagraphText>
                <ParagraphText>Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet. L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation. Lire notre politique de confidentialité  <span><a style={{ color:"green"}} href='/policy'>ici </a></span>.</ParagraphText>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphTitle>ARTICLE 6 : Propriété intellectuelle</ParagraphTitle>
                <ParagraphText>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site kodinart.com, sans autorisation préalable de Kodinart est interdite et pourra entraîner des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</ParagraphText>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphTitle>ARTICLE 7 : Droit d'auteur / Copyright ©</ParagraphTitle>
                <ParagraphText>À l’exception de l’iconographie, la reproduction des pages de ce site sur un support papier est autorisée, sous réserve du respect des trois conditions suivantes : </ParagraphText>
                <List>
                    <PolicyListItem text={'Gratuité de la diffusion'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                    <PolicyListItem text={'Respect de l’intégrité des documents reproduits (aucune modification, ni altération d’aucune sorte)'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                    <PolicyListItem text={'Citation explicite du site https://kodinart.com comme source et mention que les droits de reproduction sont réservés et strictement limités.'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                    <PolicyListItem text={'La reproduction de tout ou partie de ce site sur un support électronique est autorisée sous réserve de l’ajout de façon claire et lisible de la source https://kodinart.com et de la mention « Droits réservés ». Les informations utilisées ne doivent l’être qu’à des fins personnelles, associatives ou professionnelles ; toute utilisation à des fins commerciales ou publicitaires est exclue.'} child={<FiberManualRecord sx={{color:"black", }} fontSize='small'/>}/>
                </List>
                <Divider/>
            </ParagraphContainer>
        </MentionsTextStyle>
    );
}

export default MentionsText;