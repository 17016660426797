import { useEffect, useState } from 'react';
import Page from '../components/Pages';
import SectionType from '../components/SectionType';
import BlogMainSection from '../sections/BlogDetails/BlogMainSection';
import { BlogModel } from '../models/BlogModel';
import { useParams } from 'react-router-dom';
import { BlogsData } from '../common/Data';

function BlogDetails() {
    const [isLoading, setIsLoading] = useState(true);
    const [article, setArticle] = useState<BlogModel | undefined>(undefined);
    const params = useParams();
    const blogId = params.id

    useEffect(() => {
        const foundBlog = BlogsData.find(one => one.id === blogId);

        if (foundBlog) {
            const updatedArticle: BlogModel = {
                ...foundBlog,
                photoCard: foundBlog.photoCard || '', //  photoCard est une chaîne de caractères
                contenu: foundBlog.contenu || [], //  contenu est un tableau de chaînes de caractères
                contenuSecond: foundBlog.contenuSecond || [], //  contenuSecond est un tableau de chaînes de caractères
                imageDetailFirst: foundBlog.imageDetailFirst || '', //  imageDetailFirst est une chaîne de caractères
                imageDetailSecond: foundBlog.imageDetailSecond || '', //  imageDetailSecond est une chaîne de caractères
                nomAut: foundBlog.nomAut || '', //  nomAut est une chaîne de caractères
                photoAut: foundBlog.photoAut || '', //  photoAut est une chaîne de caractères
            };
            setArticle(updatedArticle);
            setIsLoading(false);
        }
    }, [isLoading]);


    return (
            <Page  title={'Blog Détail'}>                
                <SectionType pageTitle={"Détails Blog"}/>
                <BlogMainSection article={article}/>
            </Page>
    );
}

export default BlogDetails;