import { styled } from '@mui/material';
import React from 'react';
import ContactForm from '../../components/ContactForm';

const FormSectionStyle = styled("section")(()=> ({
    minHeight:"60vh",
    backgroundImage:"url(assets/images/trans.jpeg)",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"rgba(0, 0, 0, 0.5)",
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover",
    backgroundPosition:"center",
    opacity: 0.5
    // backgroundBlendMode:"multiply"

}))

const FormSectionBackStyle = styled("section")(()=> ({
    minHeight:"30vh",
    // width:"80%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    position:"relative",
    padding:"10vh 0vh 10vh 0vh ",
    '&::before': {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage:"url(assets/images/trans.jpeg)",// Ajoutez le chemin de votre image de masque
        backgroundSize: "cover",
        backgroundPosition: "center",
        // backgroundColor:"rgba(0, 0, 0, 0.5)",
        maskImage: "none", // Empêche le masque de se superposer sur l'élément masqué
        opacity: 0.1, // Définissez l'opacité pour ajuster l'intensité de l'effet de masque
        zIndex: -1, // Mettez l'élément masque en arrière-plan pour qu'il n'interfère pas avec le contenu de votre composant
      },
    // backgroundImage:"url(assets/images/stars.jpeg)",
    backgroundSize:"cover",
    backgroundPosition:"center",
}))

function FormSection() {
    return (
        <FormSectionBackStyle>
            <ContactForm/>
        </FormSectionBackStyle>
        // <FormSectionBackStyle>

        // </FormSectionBackStyle>
    );
}

export default FormSection;