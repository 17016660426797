import React, { useEffect, useState } from 'react';
import './App.css';
import Router from './routes';
import PagesLoader from './components/PageLoader';
import { SxProps, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import useResponsive from './hooks/useResponsive';
import CookieConsent from 'react-cookie-consent';
import { Opacity } from '@mui/icons-material';


const CookieConsentStyle: SxProps = {
  maxWidth: "390px",
  margin: "10px",
  color: "white",
  // backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  borderRadius: "10px",
  backdropFilter: "blur(8px)",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  left:"40%",
  // display:"flex",
  // flexDirection:"column",
  // justifyContent:"center",
  // alignItems:"center",
  
}
const CookieConsentStyleMobile: SxProps = {
  width: "calc(100% - 20px)",
  margin: "10px",
  color: "black",
  // backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  borderRadius: "10px",
}
const CookieConsentPPLink = styled(Link)(() =>({
}));
const CookieConsentText = styled(Typography)(() =>({
  fontFamily:"PoppinsRegular, sans-serif !important",
}));
const CookieConsentABtnStyle: SxProps = {
  color: "white",
  fontSize: "13px",
  padding: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: "30px",
  backgroundColor: "black",
}

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useResponsive("down", "md");
  const [visible, setVisible] = useState("hidden");

  useEffect(() => {
    setInterval(() => {
      setVisible("byCookieValue");
    }, 6000);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      <PagesLoader isLoading={isLoading}>
        <Router/>
        <CookieConsent
          location="bottom"
          buttonText="Accepter"
          declineButtonText="Refuser"
          cookieName="Kodi"
          style={!isMobile ? CookieConsentStyle as any : CookieConsentStyleMobile as any}
          expires={150}
          enableDeclineButton
          // flipButtons
          buttonStyle={CookieConsentABtnStyle as any}
          declineButtonStyle={CookieConsentABtnStyle as any}
          visible={visible}>
            <CookieConsentText variant='caption'>
               Nous utilisons des cookies pour améliorer votre expérience. Pour en savoir plus, consultez notre <CookieConsentPPLink to="/policy">politique de confidentialité</CookieConsentPPLink> et nos <CookieConsentPPLink to={'/mentions'}> mentions legales</CookieConsentPPLink>.
            </CookieConsentText>
        </CookieConsent>
      </PagesLoader>
  </div>
  );
}

export default App;
