import { Facebook } from '@mui/icons-material';
import { Icon, IconButton, Typography, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const SocialMediaItemStyle = styled (IconButton)(()=>({
    // width:"30%",
    // height:""
    color:"white", 
    border:"1px solid white",
    '&:hover':{
        color:"black",
        border:"1px solid black",
        backgroundColor:"white",
    },
}))

interface Props {
    children: ReactNode,
    path:string
}

function SocialMediaItem({children, path}: Props) {
    return (
        <a target='_blank' href={path}>
        <SocialMediaItemStyle size='small'>
            {children}
        </SocialMediaItemStyle>
        </a>
    );
}

export default SocialMediaItem;