import { Container, Divider, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import QualitieCard from '../../components/QualitieCard';
import { Qualities } from '../../common/Data';
import Fade from '../../animations/Fade';
import FadeVariants from '../../variants/FadeVariants';

const QualitieSectionContainer = styled("section")(({theme})=> ({
    minHeight:"30vh",
    // backgroundColor:"blue",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"8vh 0vh 10vh 0vh ",
    [theme.breakpoints.down('lg')]: {
        // fontSize:"1.1em",
        // flexDirection:"column",
        
    },
}))

export const SectionContaint = styled("div")(({theme})=> ({
    // minHeight:"60vh",
    width:"70vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"red",
    [theme.breakpoints.down('lg')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))
const SectionTitle = styled(Typography)(({theme})=> ({
    fontSize:"2.0rem",
    fontWeight:"bold",
    fontFamily:"PoppinsRegular, sans-serif !important",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },
    [theme.breakpoints.down('md')]: {
        fontSize:"1.8rem",
        },

}))

const SectionSubTitle = styled(Typography)(({theme})=> ({
    fontSize:"1.3rem",
    fontFamily:"PoppinsLight, sans-serif !important",
    marginBottom:"50px",
    [theme.breakpoints.down('md')]: {
        fontSize:"1.0rem",
        },

}))

function QualitieSection() {
    return (
        <QualitieSectionContainer>
            <SectionContaint>
            <SectionTitle>Nos  <Typography className='decorateText' component={"span"}>Qualités </Typography></SectionTitle>
            <SectionSubTitle>Les mots qui nous définissent  </SectionSubTitle>
            {/* <Container> */}
            <Grid container>                    
                {
                    Qualities.map((one, index) => <Grid key={index} item xs sx={{display:"grid", placeItems:"center"}}> <Fade  variants={FadeVariants({durationIn: 1.5}).inLeft}><QualitieCard num={one.num} title={one.title} desc={one.desc}/></Fade></Grid>)
                }
                </Grid>
            {/* </Container> */}
            </SectionContaint>
        </QualitieSectionContainer>
    );
}

export default QualitieSection;