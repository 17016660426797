import React from "react";
import { styled } from "@mui/material";

const LoaderContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));
const PagesContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));
const AnimationContainer = styled("div")(() => ({
  position: "fixed",
  top: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  backgroundColor: "white",
}));
interface Props {
  isLoading: boolean,
  children: React.ReactNode
}

function PagesLoader({ isLoading, children }: Props) {
  return (
    <LoaderContainer>
      {isLoading ? (
        <AnimationContainer sx={{ }}>
          <img src="assets/images/load.svg"/>
        </AnimationContainer> 
      ) : (
        <PagesContainer>
          { children }
        </PagesContainer>

      )}
    </LoaderContainer>
  );
}

export default PagesLoader;