import { Typography, styled } from '@mui/material';
import React from 'react';
import BlogsGrid from '../../components/BlogsGrid';

const BlogsSectionContaint = styled("section")(()=> ({
    minHeight:"100vh",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"10vh 0vh 10vh 0vh ",
}))

const TeamTextFirst = styled(Typography)(({theme})=> ({
    fontSize:"2.2em",
    fontWeight:"bold",
    fontFamily:"PoppinsBold, sans-serif !important",
    color:"black",
    marginBottom:"50px",
    // marginTop:"1em",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },    
      [theme.breakpoints.down('md')]: {
        fontSize:"1.8em",
        },


}))

function BlogsSection() {
    return (
        <BlogsSectionContaint>
            <TeamTextFirst>
               Tous Nos <Typography className='decorateText' component={"span"}>Blogs </Typography> 
            </TeamTextFirst>
            <BlogsGrid/>
        </BlogsSectionContaint>
    );
}

export default BlogsSection;