import React from 'react';
import Page from '../components/Pages';
import MentionsDetaill from '../sections/MentionsPage/MentionsDetaill';
import SectionType from '../components/SectionType';

function Mentions() {
    return (
        <Page title='Mentions Légales'>
            <SectionType pageTitle={'Mentions Légales'}/>
            <MentionsDetaill/>
        </Page>
    );
}

export default Mentions;