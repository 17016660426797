import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommentModel } from "../../models/CommentModel";
import * as comments from "../../services/firestore/comments";


export const addComment = createAsyncThunk<string, {oneAddComment: CommentModel}>(
    "comments/addComment",
    async (args) => {
        const {oneAddComment} = args;
        return await comments.addComment(oneAddComment);
    }
);

export const getComments = createAsyncThunk (
    "comments/getComments",
    async () => {
       return await comments.getComments();
    }
);

export const deleteComments = createAsyncThunk<string, {id: string}>(
    "comments/deleteComments",
     async (arg) => {
        const {id} = arg;
        return await comments.deleteComments(id).then(() => id);
    }
);

interface CommentsState {
    comments: Array<CommentModel>
};

const initialState: CommentsState = {
    comments: [],
};

const CommentSlice = createSlice({
    name: "comments",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getComments.fulfilled, (state, { payload }) => {
            state.comments = payload;
        });

        builder.addCase(deleteComments.fulfilled, (state, { payload }) => {
            state.comments = state.comments.filter(service => service.uid !== payload);
        });
    }
});

export default CommentSlice.reducer;