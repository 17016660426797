export const slides = [
    {
        id:1,
        backImage:"assets/images/1.png",
        textOne:"Nous sommes une agence moderne de création digitale",
        textTwo:"watch mee",
        desc:"looremmmmmmmmmmmmmmmmmmmmmmmmmmmm",
    },
    {
        id:2,
        backImage:"assets/images/2.png",
        // textOne:"Nous vous accompagnons dans votre transformation digitale",
        textOne:"Nous alions Modernité, Design et Sécurité",
        textTwo:"photo me",
        desc:"toooooooooooooooooooooooooooooooooooooooooo",
    },
    {
        id:3,
        backImage:"assets/images/3.png",
        textOne:"Nous vous aidons à transformer votre idée en projet",
        textTwo:"photo me",
        desc:"tooooooooooooooooooooooooooooooooooooooooooo",
    },
]
export const services = [
    {
        icone:"assets/images/ux.png",
        description:"Nous sommes spécialisés dans les tests logiciels. Nous faisons les tests bêta et alpha. Nous allons tester vos sites internet, logiciels, applications ",
        // title:"QA & Testing",
        title:"Testement",
    },
    {
        icone:"assets/images/dev.png",
        description:"Nous nous occupons de votre transformation digitale, de l'idée à la matérialisation.",
        title:"Développement Web & d'application",
    },
    {
        icone:"assets/images/idee.png",
        description:"Le design UX et UI sont importants dans le développement. Donc, nous avons des designers qui adaptent les technologies aux clients.",
        // description:"le design UX (User eXperience) et Le design UI (User Interface) sont devenus importants dans le métier du développement. C’est pour cela que nous avons des designers qui savent adapter les technologies aux différents clients.",
        title:"UI/UX Design.",
    },
    {
        icone:"assets/images/client.png",
        description:"Vous êtes une entreprise et vous avez besoin d’un technicien pour vous résoudre un soucis informatique ? ça tombe bien, nous enverrons un de nos ingénieurs sur votre site.",
        title:"Intervention sur site",
    },
    // {
    //     icone:"assets/images/idee.png",
    //     description:"Avez-vous un projet de création de site internet pour votre commerce ? Nous sommes là pour vous aider.",
    //     title:"E-commerce",
    // },
    // {
    //     icone:"assets/images/idee.png",
    //     description:"Nous donnons de la visibilité à votre entreprise & vous accompagnons à atteindre votre objectif.",
    //     title:"Marketing",
    // },
]

export const FoliosData =[
    {
        swipImgs : [
            "assets/images/af.png",
            "assets/images/aff.png",
            "assets/images/afff.png",
        ],
        pojectTitre: "AFUMA Les échassiers du TOGO",
        projectDesc: "Notre terrain d'action étendue nous a permis d'agir dans le domaine culturelle en collaborant avec un groupe de cirque AFUMA, qui offre des spectacles à travers le monde. Le groupe de cirque AFUMA est un groupe d'échassiers, de jeunes talentueux originaires du TOGO. Leur spectacle consiste à faire de belles acrobaties aériennes supportées par des échasses. Le site web par son design immersif, présente le groupe, les spectacles et un programme de leurs tournées à travers plusieurs pays du monde entier.",
        projectType: "Application WEB",
        technologie: "BOOTSTRAP/LARAVEL",
        dateProject: "Mars 2023",
        link:"https://www.afuma.org"
    },
    {
        swipImgs : [
            "assets/images/arte.png",
            "assets/images/artee.png",
            "assets/images/arteee.png",
        ],
        pojectTitre: "ARTEMIS Assurance",
        projectDesc: "Nous avons collaboré avec une compagnie d'assurance (ARTEMIS Assurance) pour concevoir et développer une application web répondant à leur besoin. Une plateforme conviviale a été mise en place non seulement pour présenter les services et vanter les exploits de la compagnie ARTEMIS Assurance, mais aussi permettre aux utilisateurs d'effectuer des réclamations , des déclarations, et aussi des demandes de devis. Une autre plateforme sécurisée et dédiée spécialement au back office de l'entreprise, a également été mise en place.",
        projectType: "Application WEB",
        technologie: "BOOTSTRAP/LARAVEL",
        dateProject: "Novembre 2022",
        // link:"afuma.org"
    },
    {
        swipImgs : [
            "assets/images/goal.png",
            "assets/images/goaal.png",
            "assets/images/goaaal.png",
        ],
        pojectTitre: "GOAL Association",
        projectDesc: "L'équipe de développeurs a été enchanté d'avoir réaliser le projet web d'une association d'aide humanitaire connue sous le nom de GOAL. Ce site représente une vitrine numérique de l'ONG GOAL, mettant en valeur sa mission, ses actions, et les projets qu'elle mène. Les visiteurs du site pourront explorer les différentes initiatives humanitaires menées par l'association, visionner la galerie de photos et vidéos inspirantes, ainsi que trouver des moyens de soutenir et de s'engager. Une fonctionalité de don en ligne facile et sécurisé a été implementé ainsi qu'un système de bénévolat pour faciliter la participation de tous ceux qui souhaitent contribuer à cette noble cause.",
        // projectDesc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti maiores ipsam sunt placeat, eos dicta porro eum, accusamus magni iure consequuntur aspernatur ex soluta. Tempora recusandae magni nemo porro eos!",
        projectType: "Application WEB",
        technologie: "BOOTSTRAP/LARAVEL",
        dateProject: "Janvier 2023"
    },
    // {
    //     swipImgs : [
    //         "assets/images/folioB.jpg",
    //         "assets/images/folioo.jpg",
    //         "assets/images/foliooo.jpg",
    //     ],
    //     pojectTitre: "ARTEMIS Assurance",
    //     projectDesc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti maiores ipsam sunt placeat, eos dicta porro eum, accusamus magni iure consequuntur aspernatur ex soluta. Tempora recusandae magni nemo porro eos!",
    //     projectType: "Application WEB",
    //     technologie: "React-TS",
    //     dateProject: "Juin 2022"
    // },
]

export const BlogsData = [
    {
        id:"6",
        photoCard:"/assets/images/ui.jpg",
        date:"12/07/2023",
        titre:"Les principes du UX/UI design : Créez des expériences utilisateur exceptionnelles sur votre site web",
        contenu: [
            `
            Afin d'attirer et d'engager les visiteurs sur un site web en conception, l'expérience utilisateur (UX) et l'interface utilisateur (UI)  se doivent obligatoirement d'etre satisfaisant. En effet le UX/UI design vise à créer des expériences interactives, intuitives et visuellement attrayantes. Nous allons dans cet article, explorer les principes fondamentaux du UX/UI design et vous fournir des conseils pour créer des expériences utilisateur exceptionnelles sur un site web
            `,
            `Comprendre d'abord le public cible
            Avant de commencer à concevoir votre site web, il est crucial de comprendre votre public cible. Cela dit, faire des recherches approfondies pour connaitre leur besoin, leur préférence et  aussi savoir se conformer à la tendance. Ce n'est qu'avec les résultats de ses recherches qu'on pourra adapter une conception et créer une expérience personnalisée qui répond spécifiquement aux attentes du public.
            `,
            `Pour la satisfaction de votre public, optez pour un design qui simplifie la navigation
            Une navigation intuitive est essentielle pour garantir une expérience utilisateur fluide. il est donc essentiel de penser à organiser le conteu de manière logique et surtout de s'assurer que la structure de navigation soit claire et cohérente. Il faudra donc faire appel à certains outils composant stylisés comme menus déroulants, des icônes et des liens pertinents, des palettes de couleurs bien organisé selon le theme et pleins d'autres...
            `,
            `Optez pour un design épuré :
            Un design épuré crée une apparence moderne et facilite la concentration des utilisateurs sur le contenu principal. Il faut choisir, dans une bonne cohérence, des couleurs qui reflètent l'identité de votre marque. Il en est de même pour les polices. Faudrait opter pour des polices assez lisibles et en même temps éviter d'en utliser trop afin de ne pas compromettre la lisibilité et les cohérence du site web.
            `,
        ],
        contenuSecond :[
            `Parcours utlisateurs fluide et réactivité
            Il est essentiel de penser à un parcours claire et simple afin de garder les utilisateurs engagé sur le site web. Pour ce faire, il faut analyser le flux de navigation, utiliser des bouttons de navigations évident et intituf, et des transitions fluide pour guider les utilisateurs d'une page à l'autre de manière transparente.
            Une conception réactive serait la meilleure approche à adopter vu le nombre d'utilisateurs qui pourraient naviguer simultanémént sur le site web. Il faut donc de ce fait prévoir l'aspect de la responsivité qui est la capacité du site à s'adapter automatiquement à tout type de résolutions d'écran et que tous les éléments restent fonctionels et facile à utiliser.
            `,
            `Test et amélioration après collecte des avis
            C'est une phase très cruciale pour garantir l'effacité du UI/UX Design. Il faut effectuer des tests utilisateurs pour collecter des avis et des commentaires sur l'expérience utilisateur. L'identification des points faibles et l'apport des modifications ne peuvent cesser d'améliorer continuellement la conception.
            `,
            `En somme le UX/UI design est un élément essentiel dans la création des sites web attrayants et conviviaux. En appliquant les principes mentionnés ci-dessus, vous pouvez offrir une expérience utilisateur exceptionnelle à vos visiteurs. N'oubliez pas de toujours vous adapter aux évolutions et aux retours des utilisateurs pour continuer à améliorer votre conception et fournir une expérience de qualité.`
        ],
        imageDetailFirst:"/assets/images/uiBlog.jpg",
        imageDetailSecond:"/assets/images/uiBlogg.jpg",
        nomAut:"KODINART",
        photoAut:"",
    },
    {
        id:"1",
        photoCard:"assets/images/nosqlU.jpg",
        date:"16/05/2023",
        titre:"Parlons un peu des bases de données non relationnelles et du NoSQL complète",
        contenu:[
            `Les bases de données jouent un rôle crucial dans la gestion et le stockage des données. Traditionnellement, les bases de données relationnelles ont été le choix privilégié pour de nombreuses applications. Cependant, ces dernières années, les bases de données non relationnelles, également connues sous le nom de bases de données NoSQL (Not Only SQL), ont gagné en popularité. Dans cet article, nous explorerons les bases de données non relationnelles et le paradigme NoSQL, en examinant leurs caractéristiques, avantages et cas d'utilisation.`,
            `Un petit débrief sur les bases de données relationels. 
            Les bases de données relationnelles ont été longtemps le choix privilégié pour la gestion des données dans de nombreux domaines. Elles reposent sur le modèle relationnel, qui organise les données en tables composées de lignes et de colonnes.
            Elles se basent généralement sur des principes de base à savoir:
            La Structure tabulaire ,le Langage de requête SQL, l'Intégrité des données, les Opérations ACID definies qui signifie Atomicité, Cohérence, Isolation, Durabilité.
            Cependant, les bases de données relationnelles traditionnelles présentent également certaines limites à savoir:
            la Scalabilité verticale,la Flexibilité des schémas, l'Adaptabilité aux données non structurées.
            Ces limites ont conduit au développement des bases de données non relationnelles, également connues sous le nom de bases de données NoSQL (Not Only SQL), qui offrent des solutions alternatives pour gérer les données dans des scénarios où les bases de données relationnelles atteignent leurs limites.

            `,
            `Introduction aux bases de données non relationels
            Les bases de données non relationnelles, également connues sous le nom de bases de données NoSQL, sont conçues pour résoudre certains des défis rencontrés par les bases de données relationnelles traditionnelles. Elles offrent des modèles de données différents et une approche plus flexible pour stocker et interroger les données. Voici les concepts clés des bases de données non relationnelles:
            `,


        ],
        contenuSecond:[
            `Flexibilité du schéma : Contrairement aux bases de données relationnelles qui exigent un schéma strict et pré-défini, les bases de données non relationnelles offrent une flexibilité du schéma. Elles permettent de stocker des données avec des structures variées, ce qui facilite l'ajout, la modification et la suppression de champs sans nécessiter de modifications du schéma global.`,
            `Distribution horizontale : Les bases de données non relationnelles sont conçues pour être facilement distribuées sur plusieurs serveurs. Elles offrent une capacité de scalabilité horizontale, ce qui signifie qu'elles peuvent gérer une augmentation de la charge en ajoutant simplement de nouveaux serveurs au lieu d'investir dans un matériel plus puissant. Cela permet d'améliorer les performances et la disponibilité des données.
            `,
            `Absence de langage de requête standardisé : Contrairement à SQL, qui est le langage de requête standardisé utilisé par les bases de données relationnelles, les bases de données non relationnelles n'ont pas de langage de requête universel. Chaque système NoSQL peut avoir son propre langage de requête ou une interface spécifique pour interagir avec les données.
            `,
            `Les bases de données non relationnelles offrent une alternative puissante aux bases de données relationnelles traditionnelles. En comprenant les différents modèles et leurs cas d'utilisation, vous serez en mesure de choisir la technologie NoSQL la mieux adaptée à votre projet.`,
        ],
        imageDetailFirst:"/assets/images/nosqlD.jpeg",
        imageDetailSecond:"/assets/images/nosqlT.jpg",
        nomAut:"KODINART",
        photoAut:"",
    },
    {
        id:"2",
        photoCard:"/assets/images/devW.jpg",
        date:"12/04/2023",
        titre:"Les dernières tendances du développement web : Construire des sites web réactifs et conviviaux",
        contenu:[
            `Dans un monde en plein essor du developpement web, il est essentiel de rester à jour avec les dernières technologies, frameworks et bibliothèques pour créer des sites web réactifs et conviviaux. De l'accessibilité à l'optimisation des performances, cet article explorera les bonnes pratiques en matière de conception web qui vous aideront à construire des expériences en ligne exceptionnelles. Préparez-vous à plonger dans les dernières tendances du développement web!
            `,
            `Les frameworks et bibliothèques populaires :
            Les frameworks et bibliothèques sont des outils essentiels pour accélérer le développement web. Parmi les plus populaires, on retrouve Angular, React-Typescript et Vue.js, Tailwindds, Svelte-Js, Next-Js et bien d'autres. Ces frameworks offrent une architecture robuste et facilitent la création de sites web réactifs grâce à leur approche basée sur les composants. Explorez les avantages et les différences entre ces frameworks pour choisir celui qui convient le mieux à votre projet. 
            `,
           
        ],
        contenuSecond: [
            `Les bonnes pratiques en matière de conception web :
            Une conception web efficace est la clé pour offrir une expérience utilisateur exceptionnelle. Pensez à adopter une approche centrée sur l'utilisateur et à créer des interfaces intuitives et esthétiquement plaisantes. L'utilisation de la typographie, des couleurs et des espaces blancs de manière cohérente peut améliorer considérablement l'attrait visuel de votre site web. De plus, assurez-vous que votre site soit adaptatif et réactif, afin qu'il s'adapte à tous les appareils et résolutions d'écran. De plus assurez-vous de bien gérer la responsivité du site qui est sa capacité de pouvoir s'adapter à tous les appareils et résolutions d'écran.
            `,
            `L'Optimisation des performances et accessibilité :
            La vitesse de chargement d'un site web est très importante pour l'expérience utilisateur. Optimisez les performances de votre site en utilisant des techniques telles que la mise en cache, la compression des fichiers, la minification du code et la diminution des requêtes réseau. L'utilisation d'outils  de test de performance de site peut vous aider à déceller des requêtes lourdes capable de ralentir votre site et aussi d'identifier des composants inutiles.
            L'accessibilité est un aspect essentiel du développement web moderne. Assurez-vous que votre site web soit accessible à tous les utilisateurs, y compris ceux qui ont des besoins spécifiques. Prenez soin de faire un bon choix d'un hébergeur qui offre une accessibilité continue et sécurisé.
            `,
            `La Pratique de développement agile :
            Le développement web moderne repose souvent sur des approches agiles. Adoptez des méthodologies telles que Scrum ou Kanban pour améliorer la collaboration, la flexibilité et l'efficacité de votre équipe de développement. Planifiez des sprints et effectuez des itérations régulières pour itérer rapidement et offrir des fonctionnalités de haute qualité à vos utilisateurs.
            `,
            `Le développement web évolue rapidement, et il est essentiel de rester à jour avec les dernières tendances et techniques. En utilisant les frameworks et bibliothèques populaires, en appliquant les bonnes pratiques en matière de conception web, en se concentrant sur l'accessibilité et en optimisant les performances, vous pouvez créer des sites web réactifs et conviviaux qui captivent les utilisateurs. Continuez à explorer, à apprendre et à expérimenter pour repousser les limites du développement web et offrir des expériences en ligne exceptionnelles.
            `,
        ],
        imageDetailFirst:"/assets/images/devWebb.jpg",
        imageDetailSecond:"/assets/images/devWeb.jpg",
        nomAut:"KODINART",
        photoAut:"",
    },
    {
        id:"3",
        photoCard:"/assets/images/ic.png",
        date:"01/03/2023",
        titre:"Utiliser l’Intégration Continue(CI) à votre projet",
        contenu:[
            `L'intégration de projets réalisés dans des langages de programmation différents est un défi courant dans le domaine du développement logiciel. Que ce soit dû à des raisons d'héritage technique, d'évolution des technologies ou de collaborations entre équipes, la nécessité de combiner ces projets en un seul système cohérent se présente fréquemment. Dans ce article de  blog, nous explorerons les étapes clés et les bonnes pratiques pour mener à bien ce processus d'intégration.
            `,
            `Analyse des projets existants:
            Avant de commencer l'intégration, il est essentiel de comprendre en détail les différents projets impliqués. Analysez chaque projet pour identifier les langages utilisés, les frameworks, les dépendances et les fonctionnalités clés. Cette analyse permettra d'obtenir une vue d'ensemble et de mieux planifier l'intégration.
            `,
            `Définir une architecture d'intégration:
            Une fois les projets analysés, définissez une architecture d'intégration claire. Identifiez les points de connexion, les interfaces et les flux de données entre les différents projets. Choisissez la meilleure approche d'intégration en fonction des exigences et des contraintes spécifiques, comme l'utilisation d'API, de services web, de bus de messages ou de l'intégration de bases de données.
            `,
            ` Établir des normes et des conventions:
            Pour assurer une intégration fluide, établissez des normes et des conventions de codage communes. Cela facilitera la collaboration entre les équipes travaillant sur les différents projets. Définissez des règles de nommage, des conventions de style, des directives de documentation et d'autres bonnes pratiques pour garantir la cohérence du code.
            `,
            `Gérer les dépendances et les incompatibilités:
            Les projets réalisés dans des langages de programmation différents peuvent avoir des dépendances et des incompatibilités. Identifiez les différences majeures entre les langages et recherchez des solutions pour résoudre les problèmes potentiels. Cela peut impliquer la création de wrappers, l'utilisation de bibliothèques de conversion ou la réécriture de certaines parties du code.
            `,
            `Planifier les étapes d'intégration:
            Divisez le processus d'intégration en étapes gérables et planifiez-les de manière méthodique. Déterminez les dépendances entre les différents composants et établissez un ordre d'intégration logique. Il peut être judicieux de commencer par des projets plus indépendants avant de passer à des intégrations plus complexes.
            `,

        ],
        contenuSecond:[
            `Mettre en place des tests approfondis:
            L'intégration de projets provenant de langages de programmation différents nécessite des tests approfondis pour garantir le bon fonctionnement du système intégré. Créez des scénarios de test couvrant différentes interactions et assurez-vous que toutes les fonctionnalités sont préservées après l'intégration. Utilisez des outils de test automatisés pour faciliter ce processus.
            `,
            `. Assurer une communication efficace:
            La communication entre les équipes travaillant sur les différents projets est essentielle pour une intégration réussie. Établissez des canaux de communication clairs pour résoudre rapidement les problèmes, partager les mises à jour et coordonner les efforts. Organisez des réunions régulières pour discuter des progrès, des défis et des ajustements nécessaires.
            `,
            `Documenter le processus d'intégration:
            N'oubliez pas de documenter soigneusement le processus d'intégration. Créez un guide détaillé qui explique les étapes, les décisions prises, les configurations spécifiques et les solutions aux problèmes rencontrés. Cette documentation sera précieuse pour référence future et pour d'autres projets d'intégration similaires.
            `,
            `L'intégration de projets réalisés dans des langages de programmation différents peut sembler complexe, mais en suivant une approche méthodique et en mettant en œuvre les bonnes pratiques, il est possible de surmonter les défis et de créer un système intégré robuste. En analysant les projets existants, en établissant des normes, en planifiant les étapes et en communiquant efficacement, vous pouvez réussir à combiner ces différents projets et à obtenir un système fonctionnel.
            `,
        ],
        imageDetailFirst:"/assets/images/icc.jpg",
        imageDetailSecond:"/assets/images/icccc.png",
        nomAut:"KODINART",
        photoAut:"",
    },
    {
        id:"4",
        photoCard:"/assets/images/techh.jpg",
        date:"03/02/2023",
        titre:"Un peu perdu dans le choix de la technologie pour son projet?",
        contenu:[
            `Lorsque vous vous lancez dans un projet, que ce soit le développement d'une application web, la création d'un site e-commerce ou la mise en place d'un système de gestion interne, choisir la bonne technologie est essentiel pour le succès de votre entreprise. Le contenu de cet article fera l'objet d'un petit guide à travers les étapes clés pour choisir la technologie la plus adaptée à votre projet, en tenant compte de divers facteurs tels que les objectifs du projet, les besoins fonctionnels, la scalabilité, la sécurité et les compétences techniques.
            `,
            `Definir et comprendre les objectifs du projet
            Avant de choisir une technologie, il est crucial de bien comprendre les objectifs de votre projet. Déterminez clairement ce que vous voulez réaliser, qu'il s'agisse de créer une plateforme de commerce électronique, de développer une application mobile ou de mettre en place un système de gestion de contenu. Identifiez les principales fonctionnalités et exigences de votre projet pour orienter votre choix technologique.
            `,
            `Evaluer les besoins fonctionels
            Analysez en détail les besoins fonctionnels de votre projet. Quelles sont les fonctionnalités spécifiques que vous devez prendre en charge ? Avez-vous besoin d'un système de gestion de base de données ? De fonctionnalités de sécurité avancées ? D'une intégration avec des services tiers ? Cette évaluation vous aidera à déterminer les technologies qui répondent le mieux à vos exigences.
            `,

        ],
        contenuSecond: [
            `Scalabilité et sécurité, des aspects importantes à prendre en compte.
            La scalabilité est un aspect crucial à prendre en compte lors du choix d'une technologie. Envisagez-vous une croissance rapide de votre projet ? Devrez-vous gérer un grand nombre d'utilisateurs ou de transactions ? Assurez-vous que la technologie choisie peut évoluer facilement pour répondre aux besoins futurs de votre projet. Évaluez la capacité de la technologie à s'adapter à une augmentation de la charge de travail et à supporter une expansion sans compromettre les performances. En ce qui concerne la sécurité, elle est primordiale dans tout projet. Évaluez les exigences de sécurité spécifiques à votre projet, telles que le chiffrement des données, l'authentification des utilisateurs et la protection contre les attaques. Recherchez des technologies qui offrent des fonctionnalités de sécurité robustes et assurez-vous de choisir une technologie maintenue régulièrement avec des correctifs de sécurité.
            `,
            `Evaluer les compétences technique Disponible et faire des comparaisons suite a des recherches
            Il est important de prendre en compte les competences techniques disponible cela dit il serait plus judicieux de choisir une technologie qui correspond aux bonne compétences des developpeurs disponible.
            Une fois que vous avez une vision claire de vos objectifs, de vos besoins fonctionnels, de la scalabilité et des compétences techniques, effectuez des recherches approfondies sur les différentes technologies disponibles. Comparez les avantages, les inconvénients, les communautés de support, les ressources disponibles et les exemples de cas d'utilisation pertinents. Consultez des forums comme Reddit, FreeCodeCamp, StackOverFlow, CodeMentor, pour obtenir des informations de première main sur les expériences d'autres porteurs de projet.
            `,
            `Choisir la bonne technologie pour votre projet est une décision stratégique qui peut avoir un impact significatif sur son succès. En comprenant vos objectifs, en évaluant les besoins fonctionnels, en considérant la scalabilité, la sécurité et les compétences techniques, vous serez en mesure de prendre une décision éclairée. N'oubliez pas que la technologie choisie doit répondre aux besoins actuels et futurs de votre projet tout en tenant compte des contraintes de votre équipe et de votre budget. Prenez le temps de rechercher, de comparer et de consulter des experts pour maximiser les chances de réussite de votre projet.`
        ],
        imageDetailFirst:"/assets/images/tech.jpg",
        imageDetailSecond:"/assets/images/techhh.jpg",
        nomAut:"KODINART",
        photoAut:"",
    },
    {
        id:"5",
        photoCard:"/assets/images/lp.jpg",
        date:"15/01/2023",
        titre:"5 raisons pour lesquelles les logiciels personnalisés sont l'avenir des entreprises",
        contenu:[
            `Dans un monde de plus en plus numérique, les entreprises se tournent de plus en plus vers les logiciels personnalisés pour répondre à leurs besoins spécifiques. Les logiciels sur étagère ne peuvent plus satisfaire toutes les exigences complexes des entreprises modernes. Les logiciels personnalisés offrent une solution sur mesure qui peut améliorer l'efficacité, la productivité et la compétitivité d'une entreprise. Dans cet article, nous explorerons cinq raisons clés pour lesquelles les logiciels personnalisés sont l'avenir des entreprises.
            `,
            `Adaptation aux besoins spécifiques de l'entreprise
            Les entreprises ont des processus uniques et des exigences spécifiques qui ne peuvent pas toujours être comblés par les solutions génériques. Les logiciels personnalisés permettent de développer des applications sur mesure, spécifiquement conçues pour répondre aux besoins de l'entreprise. Cela permet une automatisation plus efficace des tâches, une meilleure gestion des données et une optimisation des flux de travail, ce qui conduit à une plus grande efficacité opérationnelle.
            `,
            `Amélioration de la productivité et de l'efficacité
            Les logiciels personnalisés sont conçus pour rationaliser les processus internes d'une entreprise en automatisant les tâches répétitives et en éliminant les obstacles à la productivité. Grâce à une interface utilisateur intuitive et à des fonctionnalités spécifiques aux besoins de l'entreprise, les employés peuvent accomplir leurs tâches plus rapidement et de manière plus efficace. Cela permet d'économiser du temps et des ressources, ce qui se traduit par une productivité accrue et une meilleure satisfaction des employés.
            `,
            `Flexibilité et évolutivité
            Les logiciels personnalisés offrent une flexibilité incomparable par rapport aux solutions préfabriquées. Ils peuvent être adaptés et évoluer en fonction des besoins changeants de l'entreprise. Que ce soit pour ajouter de nouvelles fonctionnalités, intégrer des systèmes tiers ou prendre en compte les commentaires des utilisateurs, un logiciel personnalisé est conçu pour être flexible et évolutif. Cela permet à l'entreprise de rester agile dans un environnement commercial en constante évolution
            `,
            `Sécurité renforcée
            La sécurité des données est une préoccupation majeure pour toutes les entreprises. Les logiciels personnalisés offrent un niveau de sécurité plus élevé par rapport aux solutions génériques, car ils peuvent être spécifiquement conçus pour répondre aux exigences de sécurité de l'entreprise. Des mesures de sécurité avancées telles que le cryptage des données, l'authentification à deux facteurs et les protocoles de protection contre les intrusions peuvent être intégrées dès la conception du logiciel. Cela permet de réduire les risques de violations de données et de garantir la confidentialité des informations sensibles.
            `,
            `Avantage concurrentiel
            Dans un marché concurrentiel, avoir un avantage distinctif est essentiel pour se démarquer. Les logiciels personnalisés permettent aux entreprises de développer des fonctionnalités uniques qui répondent aux besoins de leurs clients de manière plus efficace que leurs concurrents. En offrant une expérience utilisateur exceptionnelle et des solutions sur mesure, les entreprises peuvent se différencier et fidéliser leurs clients. Les logiciels personnalisés peuvent également aider à automatiser les processus de vente et de marketing, ce qui améliore l'efficacité de l'entreprise et stimule sa croissance.
            `,
        ],
        contenuSecond:[
            `Les logiciels personnalisés représentent l'avenir des entreprises en offrant des solutions sur mesure qui répondent aux besoins spécifiques de chaque organisation. En investissant dans des logiciels personnalisés, les entreprises peuvent améliorer leur productivité, leur efficacité, leur sécurité et leur compétitivité. Avec une flexibilité et une évolutivité inégalées, les logiciels personnalisés permettent aux entreprises de s'adapter rapidement aux changements du marché. En définitive, l'adoption de logiciels personnalisés est un choix stratégique pour les entreprises qui cherchent à prospérer dans l'ère numérique en constante évolution.
            `,
        ],
        imageDetailFirst:"/assets/images/lppp.jpg",
        imageDetailSecond:"/assets/images/lpp.png",
        nomAut:"KODINART",
        photoAut:"",
    },
    {
        id:"7",
        photoCard:"/assets/images/mvcc.jpg",
        date:"27/11/2022",
        titre:"Guide complet de l'architecture MVC pour les projets de votre startup",
        contenu:[
            `Dans l'univers du développement logiciel, l'architecture MVC (Modèle-Vue-Contrôleur) est un concept fondamental largement utilisé pour organiser et structurer les projets. Si vous travaillez sur plusieurs projets au sein de votre startup, comprendre et utiliser l'architecture MVC peut grandement améliorer l'efficacité de votre équipe de développement. Dans ce blog, nous allons explorer en détail l'architecture MVC, expliquer ses composants et montrer comment les appliquer dans vos projets .
            `,
            `Comprendre l'architecture MVC:
            L'architecture MVC divise une application en trois composants principaux: le Modèle, la Vue et le Contrôleur. Chaque composant joue un rôle spécifique dans le flux de données et l'interaction avec l'utilisateur. Voici une brève description de chaque composant:
            `,
            `Modèle:
            Le Modèle représente la couche de données de l'application. Il gère la logique métier, l'accès aux données et les opérations de persistance. Le Modèle est responsable de la récupération, de la manipulation et du stockage des données, indépendamment de leur représentation ou de leur affichage.
            `,
            `Vue:
            La Vue est responsable de l'interface utilisateur de l'application. Elle affiche les données du Modèle et permet à l'utilisateur d'interagir avec l'application. La Vue est généralement passive et ne contient pas de logique métier. Elle se concentre sur la présentation des données de manière claire et intuitive.
            `,
            `Le Contrôleur agit comme un médiateur entre le Modèle et la Vue. Il gère les entrées de l'utilisateur, interprète les actions et met à jour le Modèle ou la Vue en conséquence. Le Contrôleur orchestre le flux de données et garantit que les actions de l'utilisateur sont traitées de manière appropriée.
            `,
            `Avantages de l'architecture MVC:
            `,
            `Séparation des responsabilités:
            L'architecture MVC facilite la séparation des responsabilités entre les différentes parties de l'application. Le Modèle gère la logique métier, la Vue se concentre sur l'interface utilisateur et le Contrôleur gère les interactions et la coordination globale. Cette séparation permet une meilleure maintenance et évolutivité de l'application.
            `,
            `Réutilisabilité du code:
            Grâce à la séparation claire des composants, il est plus facile de réutiliser le code dans d'autres parties de l'application ou dans des projets futurs. Le Modèle peut être utilisé indépendamment de la Vue et du Contrôleur, ce qui favorise une modularité accrue.
            `,
            `Testabilité:
            L'architecture MVC facilite également les tests unitaires et les tests d'intégration. Chaque composant peut être testé individuellement, ce qui permet de s'assurer du bon fonctionnement de chaque partie de l'application. Les tests peuvent être écrits pour vérifier les interactions entre les composants et valider les fonctionnalités spécifiques.
            `,
        ],
        contenuSecond:[
            `Implémentation de l'architecture MVC dans vos projets`,
            `Organisez votre code:
            Divisez votre code en trois dossiers principaux: Modèle, Vue et Contrôleur. Placez les fichiers liés à chaque composant dans leur dossier  respectif. Cela facilitera la navigation et la maintenance de votre code.
            `,
            `Connectez les composants:
            Assurez-vous que les composants interagissent correctement. La Vue doit pouvoir afficher les données du Modèle, tandis que le Contrôleur doit être en mesure de mettre à jour le Modèle et la Vue en fonction des actions de l'utilisateur.
            `,
            `Utilisez des frameworks MVC:
            Utiliser un framework MVC existant peut grandement faciliter la mise en œuvre de cette architecture. De nombreux frameworks populaires, tels que Django pour Python, Ruby on Rails pour Ruby et Laravel pour PHP, offrent une structure MVC prête à l'emploi.
            `,
            `L'architecture MVC est un puissant outil pour structurer et organiser vos projets de développement dans votre startup. En comprenant les rôles et les responsabilités de chaque composant (Modèle, Vue, Contrôleur) et en appliquant les principes de l'architecture MVC, vous pouvez améliorer l'efficacité de votre équipe, faciliter la maintenance du code et favoriser la réutilisation dans vos projets futurs. N'hésitez pas à explorer des frameworks MVC existants pour gagner du temps et bénéficier d'une structure solide dès le départ. Bon développement !`,
            `Les logiciels personnalisés représentent l'avenir des entreprises en offrant des solutions sur mesure qui répondent aux besoins spécifiques de chaque organisation. En investissant dans des logiciels personnalisés, les entreprises peuvent améliorer leur productivité, leur efficacité, leur sécurité et leur compétitivité. Avec une flexibilité et une évolutivité inégalées, les logiciels personnalisés permettent aux entreprises de s'adapter rapidement aux changements du marché. En définitive, l'adoption de logiciels personnalisés est un choix stratégique pour les entreprises qui cherchent à prospérer dans l'ère numérique en constante évolution.
            `,
        ],
        imageDetailFirst:"/assets/images/mvccc.png",
        imageDetailSecond:"/assets/images/mv.jpg",
        nomAut:"KODINART",
        photoAut:"",
    },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
    // {
    //     photo:"assets/images/blog.jpg",
    //     date:"12/03/2023",
    //     titre:"Sentry For Javascript monitors release",
    //     // contenu:"",
    // },
]

 export const Qualities = [
    {
        num:"01",
        title:"Le sens de l'expertise",
        desc:"Qualité et réactivité sont la clé du succès  de Kodinart",
    },
    {
        num:"02",
        title:" Le sens de l'ouverture",
        desc:"Nous faisons du code mais aussi nous sommes ouverts. Et parce que chaque avis compte, nous sommes sensibles aux retour de nos clients.",
    },
    {
        num:"03",
        title:" Le sens de l'accompagnement",
        desc:"Nous attachons une importance primordiale à l’accompagnement des collaborateurs et clients, pour que chacun puisse s’épanouir et progresser.",
    },
    {
        num:"04",
        title:" Le sens des responsabilités",
        desc:"Relever au quotidien les défis demandés par nos clients, c’est une responsabilité essentielle à laquelle nous associons des responsabilités sociétales et environnementales.",
    },
]