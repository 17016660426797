import { Fragment } from "react";
import { styled, SxProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { USER_PAGES } from "../routes/path";

const Image = styled("img")(() => ({
  objectFit: "contain"
}));
const LogoText = styled(Typography)(() => ({
  color:"white",
  fontSize:"1.5em",
  fontFamily:"NunitoExtraBold, sans-serif !important",

}));
interface Props {
  disableLink?: boolean,
  sx? : SxProps,
};

function Logo({disableLink = false, sx = {}}: Props) {

  const logo = (
    <Fragment>
      {/* <Image sx={{width: 60, height: 60, ...sx}} src="assets/images/loglorem.jpg" alt="Logo" width="100%" height="100%"/> */}
      <LogoText>kodinart</LogoText>
    </Fragment>
  );

  const logoStyle = {
    // backgroundColor:"red",
    textDecoration:"none",

  }

  if (disableLink) {
    return logo;
  }

  return <Link to={USER_PAGES.home} style={logoStyle}>{logo}</Link>;
}

export default Logo;
