import React from 'react';
import SectionType from '../components/SectionType';
import ServicePresention from '../sections/ServicePage/ServicePresention';
import TestimonySwipSection from '../sections/HomePage/TestimonySwipSection';
import LittleContactSection from '../sections/HomePage/LittleContactSection';
import Page from '../components/Pages';
import { Helmet } from 'react-helmet';

function ServicePage() {
    return (
            <Page  title={'Services'}>                
                <Helmet>
                    <meta name="author" content="KODINART"/>
                    <meta name="description" content="Découvrez nos services professionnels dans le développement web et mobile, QA et testing, et UI/UX Design. 
                                                        Notre solution, la croissance de votre entreprise." 
                    />
                    <meta name="keywords" content="services, informatiques, développement, web, mobile, QA & testing, test logiciel, UI/UX Design, mode agile" />
                </Helmet>
                <SectionType pageTitle={'Services'}/>
                <ServicePresention/>
                <TestimonySwipSection/>
                <LittleContactSection/>
            </Page>
    );
}

export default ServicePage;