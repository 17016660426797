import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import engTrans from './en.json';
import frTrans from './fr.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
              translation: engTrans,
            },
            fr: {
              translation: frTrans,
            },
          },
          fallbackLng: "fr", // Default language if the user's language is not available
          interpolation: {
            escapeValue: false,
          },
    });

    export default i18n;
