import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MailModel } from "../../models/MailModel";
import * as mails from "../../services/firestore/mails"

export const addMail = createAsyncThunk<string, {oneAddMail: MailModel}>(
    "mails/addMail",
    async (args) => {
        const {oneAddMail} = args;
        return await mails.addMail(oneAddMail);
    }
);

interface MailsState {
    mails: Array<MailModel>
};

const initialState: MailsState = {
    mails: [],
};

const MailsSlice = createSlice({
    name: "mails",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

    }
});

export default MailsSlice.reducer