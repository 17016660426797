import React from 'react';
import SectionType from '../components/SectionType';
import PortfolioDetails from '../sections/PortfolioPage/PortfolioDetails';
import LittleContactSection from '../sections/HomePage/LittleContactSection';
import Page from '../components/Pages';
import { Helmet } from 'react-helmet-async';

function Portfolio() {
    return (
            <Page  title={'Portfolio'}>                
                <Helmet>
                    <meta name="author" content="KODINART"/>
                    <meta name="description" content="Découvrez nos merveilleuses réalisation. Des projets convivial, sécurisés, réactifs et fluide. " />
                    <meta name="keywords" content="réalisation,portfolio, kodinart, projet, web, mobile" />
                </Helmet>
                <SectionType pageTitle={'Portfolio'}/> 
                <PortfolioDetails/>
                <LittleContactSection/>
            </Page>
    );
}

export default Portfolio;