import { Container, Grid, Typography, styled } from '@mui/material';
import PjojectNumberItem from '../../components/PjojectNumberItem';

const ProjectSectionStyle = styled("section")(()=> ({
    minHeight:"20vh",
    // width:"100%",
    display:"flex",
    padding:"8vh 0vh 8vh 0vh ",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
}))
const GridStyle = styled(Grid)(()=> ({
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"flex-end",
    // backgroundColor:"blueviolet"
}))
const SectionTitle = styled(Typography)(({theme})=> ({
    fontSize:"2.0rem",
    fontWeight:"bold",
    fontFamily:"PoppinsRegular, sans-serif !important",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },
    [theme.breakpoints.down('md')]: {
        fontSize:"1.8rem",
        },

}))
const SectionSubTitle = styled(Typography)(({theme})=> ({
    fontSize:"1.3rem",
    fontFamily:"PoppinsLight, sans-serif !important",
    [theme.breakpoints.down('md')]: {
        fontSize:"1.0rem",
        },

}))





function PrjectsNumber() {
    return (
        <ProjectSectionStyle>
            <SectionTitle>Faits  <Typography className='decorateText' component={"span"}>marquants </Typography></SectionTitle>
            <SectionSubTitle>Un petit apercu sur nos belles prouesses</SectionSubTitle>
            <Container>
            <Grid container>
                <GridStyle item xs>
                    <PjojectNumberItem num={'132'} backImg={'assets/images/clientt.png'} libelle={'Clients Satisfaits'}/>
                </GridStyle>
                <GridStyle item xs>
                    <PjojectNumberItem num={'90'} backImg={'assets/images/proj.png'} libelle={'Projets Réalisés'}/>
                </GridStyle>
                <GridStyle item xs>
                    <PjojectNumberItem num={'04'} backImg={'assets/images/innov.png'} libelle={"Années d'expérience"}/>
                </GridStyle>
            </Grid>
            </Container>
            {/* <SectionSubTitle>Quatre année de riches expériences & et de partage avec nos clients partout dans le monde. Tous nos clients sont satisfaits. Nous faisons l'effort de donner le meilleur de notre équipe pour satisfaire aux besoins de nos clients.</SectionSubTitle> */}
        </ProjectSectionStyle>
    );
}

export default PrjectsNumber;