import { Box, Button, Container, Grid, IconButton, Snackbar, styled, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonStyled from './ButtonStyled';
import { ContactFormModel } from '../models/ContactFormModel';
import { sendMailFunction } from '../services/cloud-functions';
import { CloseOutlined } from '@mui/icons-material';
import { useAppDispatch } from '../redux/store';
import { addMail } from '../redux/slices/Mails';
import { MailModel } from '../models/MailModel';
import { useTranslation } from 'react-i18next';


const ContactFormContainer = styled ("div")(()=>({
    // width:"calc(100% - 50px)",
    minWidth:"300px",
    // height:"calc(100% - 5px)",
    minHeight:"50vh",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",
    // background:"#F6EDDF",
    // backgroundColor:"white",
    // filter:"blur 10px",
    // opacity:"90%",
    // backdropFilter:"blur(5px)",
    // border:"1px solid",
    // borderColor:"#5C1CEB",
    borderRadius:"5px",
    alignItems:"center",
    // marginTop:"20px",
    // marginBottom:"20px",
    // padding:"25px",
    // opacity:0.8
}))


const HomeButton = styled(Link)(()=>({
    width:"125px",
    height:"32px",
    backgroundColor:"#CF9D4E",
    borderBottomLeftRadius:"15px",
    borderTopLeftRadius:"15px",
    borderBottomRightRadius:"15px",
    borderTopRightRadius:"15px",
    display:"flex", 
    flexDirection:"column", 
    justifyContent:"center", 
    alignItems:"center",
    marginTop:"10px",
    textDecoration:"none",
    color:"black"
    // borderRadius:"40%",
    // backgroundColor:"blue"
}))
const ContactText = styled(Typography)(()=>({
  fontFamily:"PoppinsBold, sans-serif !important",
  fontSize:"1.5em",
  marginBottom:"45px"
    
}))

const ButtonText = styled(Typography)(()=>({
  fontFamily:"PoppinsRegular, sans-serif !important",
  transition:"0.3s",
  '&:hover': {
      // transform:"scale(1.1)"
    },
  }))

const TextFieldPersonnaliseStyle= styled(TextField)({
    // '& input:valid + fieldset': {
    //     borderColor: 'green',
    //     borderWidth: 1,
    //   },
    //   '& input:invalid + fieldset': {
    //     borderColor: 'red',
    //     borderWidth: 1,
    //   },
    //   '& input:valid:focus + fieldset': {
    //     // borderLeftWidth: 6,
    //     padding: '4px !important', // override inline-style
    //   },
    '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '&:hover MuiInput-underline': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: "white",
      },
    } 
})

const ContactButtonStyle = styled (Button)(()=>({
  padding: '0.5em 1.5em',
  marginTop:"10px",
  width:"150px",
  border: '2px solid black',
  borderRadius:"5px",
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '16px',
  transition: '.3s',
  zIndex: 1,
  fontFamily: 'inherit',
  color: 'black',
  '&::before': {
      content: '""',
      width: 0,
      height: '325%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      background: 'black',
      transition: '.5s ease',
      display: 'block',
      zIndex: -1,
    },
    '&:hover::before': {
      width: '105%',
    },
    '&:hover': {
      color: 'white',
    },
}))


function ContactForm() {
  const  {t} = useTranslation();
  const [formData, setFormData] = useState<ContactFormModel>(
    {name:"", email:"", message:""}
  )
  const dispatch = useAppDispatch();


  const handleEmpty = (event: React.FormEvent) => {
    event.preventDefault();
    const data: MailModel = {
      to: 'contact@kodinart.com',
      message: {
        subject:"Contact Client",
        // html: `Nom du client: ${formData.name}<br></br> 
        // Email du client: ${formData.email}<br></br>
        // Message: ${formData.message}<br></br>        
        // `
        html: `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Document</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap">
            <style>
            <style>
                    body{
                        text-align: center;
                    }
                    .logo {
                    display: block;
                    margin: 0 auto;
                    width: 10%;
                    height: 10%, /* Ajustez la largeur selon vos besoins */
                }
                
                .txt {
                  color: blue,
                }
            </style>
        </head>
        
        <body>
            <img src="{{asset('assets/images/Art.png')}}" class="logo"alt=""> <br>        
            <h1> Contact Client </h1>
        
         <div>
            <h2 class="txt"> Kodinart </h2>
           
           <p> Nom et Prénom : <strong> ${formData.name}  </strong></p>
                   
           <p> Email : <strong>${formData.email}</strong></p>
           
           <p> Message : <strong> ${formData.message} </strong></p>
        </div>
         
        </body>
        </html>   
        `
      }
    }
    dispatch(addMail({oneAddMail: data})).unwrap().then(()=> {
      handleOpenBar();
      setFormData({name:"", email:"", message:"" });
    })
    // sendMailFunction({name: formData?.name, email:formData?.email, message:formData?.message}).then(handleOpenBar);
    // setFormData({name:"", email:"", message:"" });
    // console.log(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({...prev, [e.target.name]: e.target.value} as ContactFormModel));
    console.log(formData);
   };

   const [openBar, setOpenBar] = useState(false);

        const handleOpenBar = () => {
            setOpenBar(true);
          };
    
          const handleCloseBar = (event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
              return;
            }
        
            setOpenBar(false);
          };

          const action = (
            <React.Fragment>
              <Button color="secondary" size="small" onClick={handleCloseBar}>
                OK
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseBar}
              >
                <CloseOutlined fontSize="small" />
              </IconButton>
            </React.Fragment>
          );
    return (
          <Box component={"form"} onSubmit={handleEmpty}>
        <ContactFormContainer>
          <ContactText>{t('form')}</ContactText>

            <Container>
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                            <TextField name="name"  variant="outlined" label={"Nom et Prénoms"} required={true} type="text" fullWidth sx={{color:"white"}} onChange={handleChange} value={formData?.name}/>
                        </Grid>
                        <Grid item lg={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                            <TextField name="email"  variant="outlined" label={"Email"} type="email" required={true} fullWidth onChange={handleChange} value={formData?.email}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="message"  variant="outlined" label={"Votre Message"} required={true} type='text' fullWidth  multiline rows={6} onChange={handleChange} value={formData?.message}/>
                        </Grid>
                    </Grid>
                    {/* <ButtonContaine>
                        <Button variant="contained" sx={{backgroundColor:"#FF8000", width:"200px", height:"50px", marginTop:"50px"}} onClick={submit}>Envoyer</Button>
                    </ButtonContainer> */}
                </Container>
                <ContactButtonStyle type='submit'><ButtonText>Envoyer</ButtonText></ContactButtonStyle>
                <Snackbar open={openBar} autoHideDuration={5000} onClose={handleCloseBar} message="Votre Message a été envoyé avec succès." action={action} />     

                {/* <Button variant='outlined' sx={{color:"black", padding:"15px", fontSize:14, fontWeight:"bold"}}>ENVOYER</Button> */}
        </ContactFormContainer>
          </Box>
    );
}

export default ContactForm;