import { AppBar, Button, Container, Grid, styled } from '@mui/material';
import React, { useState } from 'react';
import HeaderElementsContainer from '../HeaderElementsContainer';
import MyDrawer from '../MyDrawer/MyDrawer';
import { Menu } from '@mui/icons-material';
import Logo from '../Logo';
import SocialMediaMenu from './SocialMediaMenu';
import HeaderAnim from '../../animations/HeaderAnim';
import LangChange from '../LangChange';
import useResponsive from '../../hooks/useResponsive';

const MenuButtonStyle = styled (Button)(()=>({
    color:"white"
}))

function Header() {
    const isMobile = useResponsive("down", "lg");
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOPenDrawer= () => {
        setOpenDrawer(!openDrawer)
    }
    return (
        <AppBar >
            <HeaderAnim>
                <Container maxWidth={false} >
                    {/* <Grid container sx={{...(isMobile && {paddingLeft: "0px", paddingRight: "0px"})} && {...(!isMobile && {paddingLeft: "50px", paddingRight: "50px"})}}> */}
                    <Grid container  sx={{...(isMobile && {paddingLeft: "0px", paddingRight: "0px"})} && {...(!isMobile && {paddingLeft: "40px", paddingRight: "40px"})}}>
                        <Grid item lg={1} xs sx={{}}>
                             {/* <Logo sx={{marginLeft: "30px"}} logoImg="images/logoblancnoirrongné.png"/> */}
                             <Logo/>
                        </Grid>
                        <Grid item lg={11} xs sx={{ display: "flex", alignItems: "center", justifyContent:"flex-end", }}> 
                            {!isMobile &&  <SocialMediaMenu/>}
                            <LangChange/>
                            <MenuButtonStyle onClick={handleOPenDrawer}><Menu/></MenuButtonStyle>
                            <MyDrawer opening={openDrawer} closing={handleOPenDrawer}/>                                      
                        </Grid>
                    </Grid>
                </Container>
            </HeaderAnim>
        </AppBar>
    );
}

export default Header;