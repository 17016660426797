import { styled } from '@mui/material';
import React, { ReactNode } from 'react';

const BackCarouselStyle = styled ("div")(()=>({
    position:"absolute",
    width:"100%",
    height:"100%",
}))

interface Props {
    children:ReactNode
}

function BackCarousel({children}: Props) {
    return (
        <BackCarouselStyle>
            {children}
        </BackCarouselStyle>
    );
}

export default BackCarousel;