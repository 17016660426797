import { PhoneAndroid } from '@mui/icons-material';
import { Card, Fade, Icon, Typography, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import TextButton from './TextButton';

const InfoCardStyle = styled(Card)(({theme})=>({
    width:"300px",
    height:"175px",
    borderRadius:"20px",
    backgroundColor:"black",
    border:"1px solid #2A2A2A",
    // padding:"10px",
    flexDirection:"column",
    display:"grid",
    placeItems:"center",
    // transition: '0.5s ease',
    // transition:"border all ease-in-out 0.3s",
    transition: 'border 0.3s ease-in-out',
    '&:hover': {
        // border:"1px solid #2A2A2A",
        border:"1px solid black",
        // transform:"translate(-1px, -1px)",
    },
    [theme.breakpoints.down('sm')]: {
        // width:"95%",
    },
}));
const InfoCardLeft = styled("div")(()=>({
    width:"30%",
    height:"100%",
    // backgroundColor:"red",
    display:"grid",
    placeItems:"center"
}));
const InfoCardRight = styled("div")(()=>({
    width:"70%",
    height:"100%",
    // backgroundColor:"powderblue",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",
    alignItems:"start"
}));

const ContactElementIconContainer = styled("div")(({theme})=>({
    width:"95px",
    height:"95px",
    borderRadius:"50%",
    backgroundColor:"#121212",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
}))
const ContactElementTitle = styled(Typography)(({theme})=>({
    fontFamily:"PoppinsRegular, sans-serif !important",
    color:"#BDBDBD",
    fontSize:"1.3em"
}))
const ContactElementLink = styled("a")(({theme})=>({
    fontFamily:"PoppinsLight, sans-serif !important", 
    color:'#BDBDBD', 
    textAlign:"end",
    textDecoration:"none"
}))


interface Props {
    icon:ReactNode,
    elementTitle:string,
    elementLink:string,
    path:string
}
function InfoCard({icon, elementTitle, elementLink,path}: Props) {
    return (
        // <Fade in={true} timeout={1000} >
            <InfoCardStyle>
                <ContactElementIconContainer>                   
                        {icon}
                </ContactElementIconContainer>
                <ContactElementTitle>
                    {elementTitle}
                </ContactElementTitle>
                <ContactElementLink target='blank' href={path}>{elementLink}</ContactElementLink>
                {/* <TextButton title={elementLink} path={path} sx={{}}/> */}
        </InfoCardStyle>
        // </Fade>
    );
}

export default InfoCard;