import { Stack, SxProps, Typography, styled } from '@mui/material';
import React from 'react';
import { Navigation, Pagination, Autoplay, EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/effect-creative";
import "swiper/css";


const PortfolioModStyle = styled("div")(({theme}) => ({
    minHeight:"50vh",
    width:"70vw",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"royalblue",
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        width:"90%",
        // backgroundColor:"yellow",
        },

}));

const PortfolioDetailsLeft = styled("div")(({theme})=> ({
    minHeight:"75vh",
    width:"65%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"pink",
    [theme.breakpoints.down('md')]: {
        width:"100%",
        // minHeight:"30vh",
        // backgroundColor:"green",
        },
}))
const PortfolioDetailsRight = styled("div")(({theme})=> ({
    minHeight:"75vh",
    width:"35%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"flex-start",
    // backgroundColor:"green",
    [theme.breakpoints.down('md')]: {
        width:"100%",
        minHeight:"50vh",
        // backgroundColor:"brown",
        },
}))

const SwipeContent = styled("div")(({theme})=> ({
    minHeight:"50vh",
    width:"85%",
    // display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"3px",
    // backgroundColor:"red",
    // border:"5px solid black",
    [theme.breakpoints.down('md')]: {
        width:"100%",
        height:"30vh",
        // minHeight:"30vh",
        // backgroundColor:"green",
        },
}))
const SwipeImg = styled("img")(()=> ({
    height:"50vh",
    width:"100%",
    objectFit:"cover",

}))
const FolioName = styled(Typography)(()=> ({
    fontFamily:"PoppinsBold, sans-serif !important",
    fontSize:"1.5em",


}))
const FolioDesc = styled(Typography)(()=> ({
    fontFamily:"PoppinsRegular, sans-serif !important",
    marginTop:"3vh",
    marginBottom:"4vh",
    fontSize:"0.9em",
    textAlign:"justify",


}))
const FolioLib = styled(Typography)(()=> ({
    fontFamily:"PoppinsRegular, sans-serif !important",
    fontSize:"0.9em",

    // height:"50vh",
    // width:"100%",

}))
const FolioVal = styled(Typography)(()=> ({
    fontFamily:"PoppinsLight, sans-serif !important",

    // height:"50vh",
    // width:"100%",

}))
const FolioInfo = styled("div")(()=> ({
    // height:"50vh",
    // width:"100%",

}))
const OneInfo = styled(Stack)(()=> ({
    marginBottom:"10px"
    // height:"50vh",
    // width:"100%",

}))

interface Props {
    swipImage: string[],
    projectName: string,
    description: string,
    tech: string,
    typeProject:string,
    date: string,
    sx? : SxProps,
    lien?:string

}

function PortfolioMod({swipImage,date,typeProject,tech,projectName,description, sx = {}, lien}: Props) {
    return (
        <PortfolioModStyle sx={{ ...sx}}>
            <PortfolioDetailsLeft>
                    <SwipeContent>
                        <Swiper
                            effect={"creative"}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={true}
                            modules={[Pagination, Navigation, Autoplay, EffectCreative]}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                              creativeEffect={{
                                prev: {
                                  shadow: true,
                                  translate: [0, 0, -400],
                                },
                                next: {
                                  translate: ["100%", 0, 0],
                                },
                              }}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            className="mySwiper"
                        >

                            {swipImage.map((one, index) => <SwiperSlide><SwipeImg src={one}/></SwiperSlide>)}
                        </Swiper>
                    </SwipeContent>
                </PortfolioDetailsLeft>
                <PortfolioDetailsRight>
                    <FolioName>
                        {projectName}
                    </FolioName>
                    <FolioDesc>
                        {description}
                    </FolioDesc>
                    <FolioInfo>
                        <OneInfo >
                            <FolioLib>Type De Projet:</FolioLib><FolioVal>{typeProject}</FolioVal>
                        </OneInfo>
                        <OneInfo >
                            <FolioLib>Technologie:</FolioLib><FolioVal>{tech}</FolioVal>
                        </OneInfo>
                        <OneInfo >
                            <FolioLib>Date:</FolioLib><FolioVal>{date}</FolioVal>
                        </OneInfo>
                        <OneInfo >                            
                            <a target='_blank' href={lien}><FolioVal>Voir le site</FolioVal></a>
                        </OneInfo>
                    </FolioInfo>
                </PortfolioDetailsRight>
        </PortfolioModStyle>
    );
}

export default PortfolioMod;