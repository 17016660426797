import { Avatar, Box, Card, Typography, styled } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import BlogOverviewCard from './BlogOverviewCard';

const TestimonyCardStyle = styled(Box)(()=>({
    // width:"400px",
    width:"90%",
    height:"100%",
    // backgroundColor:"blue",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",
    alignItems:"center",
    '&:hover': {
      },
    // position:"relative"
}))
const TestimonyText = styled(Typography)(({theme})=> ({
    marginBottom:"1.75rem",
    fontSize:"1.1rem",
    // lineHeight:"1.5rem",
    textAlign:"center",
    fontStyle:"italic",
    fontFamily:"PoppinsRegular, sans-serif !important",
    width:"60%",
    [theme.breakpoints.down('lg')]: {
        // fontSize:"0.95rem",
        width:"85%",
        
    },
    [theme.breakpoints.down('md')]: {
        fontSize:"1.05rem",
        width:"90%",
        
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"0.95rem",
        width:"95%",
        
    },

}))
const TestimonyAuthor = styled("div")(()=> ({
    
}))
const AuthorImg = styled("img")(()=> ({
    width:"120px",
    height:"120px",
    marginBottom:"2.25rem",
    borderRadius:"50%",
    
}))
const AuthorName = styled(Typography)(({theme})=> ({
    marginBottom:"0.25rem",
    fontFamily:"PoppinsBold, sans-serif !important",
    fontSize:"1.25rem",
    lineHeight:"1.75rem",
    [theme.breakpoints.down('md')]: {
        fontSize:"1.10em",
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"1.0em",
    },

}))
const AuthorAvatar = styled(Avatar)(({theme})=> ({
    width:125,
    height:125,
    marginBottom:"2.25rem",
    [theme.breakpoints.down('md')]: {
        width:110,
        height:110,
        marginBottom:"2.0rem",
    },
    [theme.breakpoints.down('sm')]: {
        width:95,
        height:95,
        marginBottom:"1.75rem",
    },

}))
interface Props {
    text:string,
    nomAut:string,
    image:string,
}


function TestimonyCard({text,image,nomAut}: Props) {

    return (
       <TestimonyCardStyle>
            {/* <AuthorImg src='assets/images/womenn.jpg'/> */}
            <AuthorAvatar src={image}/>
            <TestimonyText>
                {text}
                {/* “Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae perferendis sequi pariatur fuga et optio. Similique reiciendis, ipsa praesentium sed soluta recusandae dolorum quia accusamus commodi minima, possimus unde odio?” */}
            </TestimonyText>
            <AuthorName>
                {/* MORIJAH JOLIE */}
                {nomAut}
            </AuthorName>
       </TestimonyCardStyle>
    );
}

export default TestimonyCard;