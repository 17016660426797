import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import FolioItem from '../../components/FolioItem';
import Fade from '../../animations/Fade';
import FadeVariants from '../../variants/FadeVariants';

const FolioSectionStyle = styled("section")(({theme})=> ({
    minHeight:"75vh",
    // width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:"black",
    // paddingBottom:"30px",

}))

export const SectionContaint = styled("div")(({theme})=> ({
    // minHeight:"500px",
    // width:"80%",
    width:"70vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"10vh 0vh 10vh 0vh ",
    // marginBottom:"25px",
    // backgroundColor:"red",
    [theme.breakpoints.down('lg')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        // marginBottom:"15px"
        },
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        // marginBottom:"15px"
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))

const FolioTitleStyled = styled(Typography)(({theme})=> ({
    fontSize:"2.0rem",
    fontWeight:"bold",
    color:"white",
    fontFamily:"PoppinsRegular, sans-serif !important",
    // margin:"50px 0px 10px 0px",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px white",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },
    [theme.breakpoints.down('md')]: {
        fontSize:"1.8rem",
        },
}))

const FolioTitle = styled(Typography)(()=> ({
    fontSize:"2.5em",
    fontWeight:"bold",
    color:"white"

}))

const GridStyle = styled(Grid)(()=> ({
    display:"grid",
    placeItems:"center",
    // flexDirection:"row",
    // justifyContent:"center",
    // alignItems:"flex-end",
    // backgroundColor:"blueviolet"
}))

const SectionSubTitle = styled(Typography)(({theme})=> ({
    fontSize:"1.3rem",
    fontFamily:"PoppinsLight, sans-serif !important",
    color:"white",
    marginBottom:"50px",
    [theme.breakpoints.down('md')]: {
        fontSize:"1.0rem",
        },

}))

function FolioOverview() {
    return (
        <FolioSectionStyle>
            <SectionContaint>
            <FolioTitleStyled >Nos Récents  <Typography className='decorateText' component={"span"}>Projets </Typography></FolioTitleStyled>
            <SectionSubTitle>Découvrez Nos Réalisations</SectionSubTitle>
            {/* <Container> */}
                <Grid container rowSpacing={5}>
                    <GridStyle item xs={12} sm={6} md={6} lg={4}>
                        <Fade  variants={FadeVariants({durationIn: 1}).inUp} sxFade={{display:"grid", placeItems:"center"}}>
                            <FolioItem projectName={'ARTEMIS Assurance'} coverImg={'assets/images/ar.jpg'}/>
                        </Fade>
                    </GridStyle>
                    <GridStyle item xs={12} sm={6} md={6} lg={4}>
                        <Fade  variants={FadeVariants({durationIn:1}).inUp} sxFade={{display:"grid", placeItems:"center"}}>
                            <FolioItem projectName={'AFUMA'} coverImg={'assets/images/afumaD.jpg'}/>
                        </Fade>
                    </GridStyle>
                    <GridStyle item xs={12} sm={6} md={6} lg={4}>
                        <Fade  variants={FadeVariants({durationIn:1}).inUp} sxFade={{display:"grid", placeItems:"center"}}>
                            <FolioItem projectName={'GOAL Association'} coverImg={'assets/images/goalOv.jpg'}/>
                        </Fade>
                    </GridStyle>
                    {/* <GridStyle item xs={12} sm={6} md={6} lg={4}>
                        <Fade  variants={FadeVariants({durationIn:1}).inUp} sxFade={{display:"grid", placeItems:"center"}}>
                            <FolioItem projectName={''} coverImg={''}/>
                        </Fade>
                    </GridStyle>
                    <GridStyle item xs={12} sm={6} md={6} lg={4}>
                        <Fade  variants={FadeVariants({durationIn:1}).inUp} sxFade={{display:"grid", placeItems:"center"}}>
                            <FolioItem projectName={''} coverImg={''}/>
                        </Fade>
                    </GridStyle>
                    <GridStyle item xs={12} sm={6} md={6} lg={4}>
                        <Fade  variants={FadeVariants({durationIn:1}).inUp} sxFade={{display:"grid", placeItems:"center"}}>
                            <FolioItem projectName={''} coverImg={''}/>
                        </Fade>
                    </GridStyle> */}
                </Grid>
            {/* </Container> */}
            </SectionContaint>
        </FolioSectionStyle>
    );
}

export default FolioOverview;