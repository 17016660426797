export const OTHER_PAGES = {
    page404: "/404",
    page500: "/500",
};

export const USER_PAGES = {
    home: "/",
    service:"/service",
    portfolio:"/portfolio",
    blogs:"/blogs",                       
    mentions:"/mentions",
    blogsDetail:"/blogsDetail/:id",
    // homeColors:"/homeColors",
    // homeNeon:"/homeNeon",
    // hideMenu:"hideMenu",
    about:"/about",
    contact:"/contact",
    policy:"/policy",
};