import React from 'react';
import { styled, SxProps, Typography } from '@mui/material';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import BlogOverviewCard from './BlogOverviewCard';
import TestimonyCard from './TestimonyCard';

const TestComponentContainer = styled('div')(() => ({
    width:"100%",
    minHeight:"40vh",
    // backgroundColor:"blueviolet",
    // margin:"20px",
    marginRight:"20px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",

}));

interface Props {
    text:string,
    nomAut:string,
    image:string,
}

function TestComponent({text,image,nomAut}: Props) {
    return (
       <TestComponentContainer>
            {/* <BlogOverviewCard/> */}
            <TestimonyCard text={text} nomAut={nomAut} image={image}/>
       </TestComponentContainer>
    );
}

export default TestComponent;