import { Button, Typography, styled } from '@mui/material';
import React from 'react';

const ButtonStyledStyle = styled (Button)(()=>({
  padding: '0.5em 1.5em',
  width:"150px",
    // padding: '0.8em 1.8em',
    // width:"10%",
    // border: '2px solid white',
    border: '2px solid black',
    borderRadius:"5px",
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    transition: '.3s',
    zIndex: 1,
    fontFamily: 'inherit',
    color: 'black',
    '&::before': {
        content: '""',
        width: 0,
        height: '325%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        background: 'black',
        transition: '.5s ease',
        display: 'block',
        zIndex: -1,
      },
      '&:hover::before': {
        width: '105%',
      },
      '&:hover': {
        color: 'white',
      },
}))

const ButtonText = styled(Typography)(()=>({
  fontFamily:"PoppinsRegular, sans-serif !important",
  transition:"0.3s",
  '&:hover': {
      // transform:"scale(1.1)"
    },
  }))

  interface Props {
    content:string,
  }

function AboutStyledButton({content} : Props) {
    return (
        <ButtonStyledStyle>
           <ButtonText>
            {content}
           </ButtonText>
        </ButtonStyledStyle>
    );
}

export default AboutStyledButton;