import {  Fade, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import TypewriterComponent, { TypewriterClass } from 'typewriter-effect';
import ButtonStyled from './ButtonStyled';
// import Fade from '../animations/Fade';
import FadeVariants from '../variants/FadeVariants';
import SlideTextOne from './SlideTextOne';
import { USER_PAGES } from '../routes/path';
import { useTranslation } from 'react-i18next';

const SlideStyle = styled("div")(({theme})=>({
    // width:"100%",
    // minHeight:"100vh",
    // // position:"absolute",
    // // backgroundImage: `url(images/gymMeuf.png)`,
    // backgroundSize:"cover",
    // objectFit:"cover",
    // backgroundPosition:"center",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"center",
    // alignItems:"start",
    transition: '1s ease-out',
    backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundColor:'royalblue',
        // minHeight: '90vh',
        // height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        paddingTop: "50px",
        [theme.breakpoints.down('lg')]: {
        //   height: '80vh',
        alignItems:"center",
        // paddingTop: "50px",
        },
        [theme.breakpoints.down('md')]: {
        //   height: '80vh',
        alignItems:"center",
        // paddingTop: "100px",
        },
        [theme.breakpoints.down('sm')]: {
        //   height: '60vh',
        },
}))
export const SectionContaint = styled("div")(({theme})=> ({
    minHeight:"100vh",
    // padding:"0vw 12vw 0vw 12vw",
    // width:"80%",
    width:"65vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"start",
    // backgroundColor:"red",
    // [theme.breakpoints.down('xl')]: {
    //     width:"60%",
    //     // backgroundColor:"red",
    //     },
    // [theme.breakpoints.up('lg')]: {
    //     width:"60%",
    //     // backgroundColor:"red",
    //     },
    [theme.breakpoints.down('lg')]: {
        width:"90%",
        // backgroundColor:"red",
        },
    [theme.breakpoints.down('md')]: {
        alignItems:"center",
        width:"90%",
        // backgroundColor:"red",
        },
    [theme.breakpoints.down('sm')]: {
        alignItems:"center",
        width:"95%",
        // backgroundColor:"blue",
        },
}))

const LittleDesc = styled(Typography)(({theme})=>({
    fontSize:"18px",
    width:"52%",
    color:"white",
    
    fontFamily:"PoppinsRegular, sans-serif !important",
    [theme.breakpoints.down('lg')]: {
        fontSize:"18px",
        width:"75%",
    },
    [theme.breakpoints.down('md')]: {
        fontSize:"1.2em",
        width:"100%",
        textAlign:'center',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"0.8em",
        width:"100%",
        textAlign:'center',
    },
    // fontSize:"20px",
    // width:"40%",
    // color:"white",
    // marginLeft:"150px",
    // fontFamily:"PoppinsRegular, sans-serif !important",
    // transition:"opacity 5s ease-in",
    // opacity:1,
    // // transitionProperty:"all",
    // // transitionDuration:"2s",
    // '&:hover':{
    //     color:"blue"
    // }


    
}))
const ListText = styled(Typography)(({theme})=>({
    fontSize:"20px",
    // width:"52%",
    color:"white",
    margin:"20px 0px 10px 0px",
    
    fontFamily:"YsabeauRegular, sans-serif !important",
    [theme.breakpoints.down('lg')]: {
        
    },
    [theme.breakpoints.down('md')]: {
        textAlign:'center',
    },
    [theme.breakpoints.down('sm')]: {
        textAlign:'center',
    },
    
}))
const HeroTextOne = styled(Typography)(({theme})=>({
    // color:"#F8F8F8", 
    color:"whitesmoke", 
    // fontWeight:"bold",
    width:"65%",
    fontSize:"3.2em",
    lineHeight:"1.0em",
    opacity:0,
    WebkitTransform:"translateY(100px)",
    WebkitTransition:"all 500ms ease",
    // marginBottom:"2vh",
    // fontSize:"1.3em",
    // transition: 'blue 0.5s ease-in-out',
    fontFamily:"PoppinsBold, sans-serif !important",
    '&.textTransition' : {
        transition: '1s ease-out',
        // color:"red",
        opacity: 1,
        WebkitTransform:" translateY(0px)",
    },
    [theme.breakpoints.down('lg')]: {
        fontSize:"4.0em",
        width:"95%",
        lineHeight:"0.8em",
    },
    [theme.breakpoints.down('md')]: {
        fontSize:"3.5em",
        width:"95%",
        textAlign:'center',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"2.5em",
        width:"100%",
        textAlign:'center',
    }, 

    
}))
const HeroTextTwo = styled(Typography)(({theme})=>({
    color:"transparent",
    WebkitTextStroke:"2px #ffff",
    WebkitTextFillColor:"transparent",
    fontFamily:"PoppinsBold, sans-serif !important",
    fontWeight:800,
    fontSize:"2.8em",
    marginTop:"5px",
    marginBottom:"15px",
    [theme.breakpoints.down('lg')]: {
        fontSize:"3.0em",
    },
    [theme.breakpoints.down('md')]: {
        fontSize:"2.5em",
        textAlign:'center',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"1.5em",
        textAlign:'center',
    },

}))



interface Props {
    image:string,
    firstText:string,
    secondText:string,
    pageLink:string,
    descrip:string,
    aSlide?:number,
    keyUnique:number

}

function SlideType({image, firstText, secondText, pageLink, descrip, aSlide, keyUnique}: Props) {
    const  {t} = useTranslation();


    const heroTextAnim = (typewriter: TypewriterClass) => {
        typewriter.typeString(t("anim"))
        // typewriter.typeString("Expertise & Ouverture")
        .pauseFor(1000)
        .deleteAll()
        .typeString(t("anim2"))
        .pauseFor(1000)
        .deleteAll()
        .start();
    }

    const slideStyles = {
        backgroundImage: `url('${image}')`,
        transition: 'background-image 1s ease-out',
        // transition: '1s ease-out'

      };
    return (
        <SlideStyle key={keyUnique} style={slideStyles}>
        {/* <SlideStyle key={keyUnique} sx={{backgroundImage:"url('"+image+"')"}}> */}
            <SectionContaint>
                {/* <Fade variants={FadeVariants({durationIn: 2}).inDown}> */}
                {/* <Fade in={true} timeout={1000} > */}
                {/* <SlideTextOne text={firstText} aSlide={aSlide}/> */}
                <SlideTextOne text={firstText} />
                {/* <HeroTextOne className={aSlide ? "textTransition" : ""}> */}
                        {/* {firstText} */}
                        {/* Besoin de réaliser une idée de Projet? */}
                    {/* </HeroTextOne> */}
                {/* </Fade> */}
                {/* </Fade> */}
                    {/* <ListText>Test Logiciel . UI/UX Design . Developpement Web . Marketing Digital</ListText> */}

                <HeroTextTwo>
                    {/* {secondText} */}
                    <TypewriterComponent onInit={heroTextAnim} options={{loop:true, deleteSpeed:1, delay:25}}/>
                </HeroTextTwo>
                {/* <LittleDesc> */}
                    {/* {descrip} */}
                    {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat natus ducimus expedita similique est earum nulla molestias corrupti molestiae rem. */}
                {/* </LittleDesc> */}
                <ListText>QA & Testing  •  UI/UX Design  •  Developpement Web </ListText>

                <Stack direction={"row"} spacing={5} sx={{ marginTop:"30px"}}>
                <ButtonStyled content={'CONTACT'} path={USER_PAGES.contact}></ButtonStyled>
                <ButtonStyled content={'PROJETS'} path={USER_PAGES.portfolio}></ButtonStyled>
                </Stack>
            </SectionContaint>
        </SlideStyle >
    );
}

export default SlideType;