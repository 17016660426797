import React from 'react';
import SectionType from '../components/SectionType';
import BlogsGrid from '../components/BlogsGrid';
import BlogsSection from '../sections/BlogPage/BlogsSection';
import Page from '../components/Pages';
import { Helmet } from 'react-helmet';

function BlogPage() {
    return (
            <Page  title={'Blog'}>                
                <Helmet>
                    <meta name="author" content="KODINART"/>
                    <meta name='description' content="Explorez notre collection de blogs informatiques sur le développement web, le UI/UX Design et sur d'autres sujets connexes. Obtenez des conseils,
                                                     des astuces et des informations utiles pour améliorer vos compétences et rester à jour dans le domaine de l'informatique."></meta>
                    <meta name='keywords' content='blogs, informatiques, KODINART, développement web, developpment, Mobile, tendances, technologiques, UI/UX , 
                                                        Design, base, données , non-relationel, choix, technologies,'></meta>
                </Helmet>
                <SectionType pageTitle={'Blogs'}/>
                <BlogsSection/>
            </Page>
    );
}

export default BlogPage;