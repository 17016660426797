import { Button, Divider, Grid, List, Typography, styled } from '@mui/material';
import React from 'react';
import ImageComponent from '../../components/ImageComponent';
import AboutListItem from '../../components/AboutListItem';
import AboutStyledButton from '../../components/AboutStyledButton';
import Fade from '../../animations/Fade';
import FadeVariants from '../../variants/FadeVariants';
import { Link } from 'react-router-dom';
import { USER_PAGES } from '../../routes/path';

const AboutHeroContainer = styled("section")(({theme})=> ({
    minHeight:"50vh",
    // width:"100%",
    // position: "relative",
    // backgroundColor: "blueviolet",
    overflow: "hidden",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down('md')]: {
        fontSize:"1.1em",
        flexDirection:"column",
        paddingBottom:"15px"
    },
}))

export const SectionContaint = styled("div")(({theme})=> ({
    // minHeight:"85vh",
    width:"70vw",
    display:"flex",
    padding:"10vh 0vh 10vh 0vh ",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"red",
    
    [theme.breakpoints.down('lg')]: {
        // backgroundColor:"red",
        width:"95%",
        // padding:"20px 0px 20px 0px"
        },
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"green",
        width:"90%",
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))

const AboutHeroLeft = styled("div")(({theme})=> ({
    width:"50%",
    // backgroundColor:"yellow",
    // minHeight:"500px",
    display:'flex',
    flexDirection:"column",
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.down('md')]: {
        width:"100%",
        // backgroundColor:"yellow",
        },
}))
const AboutHeroRight = styled("div")(({theme})=> ({
    width:"50%",
    // backgroundColor:"violet",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"flex-start",
    // minHeight:"7    00px",
    [theme.breakpoints.down('md')]: {
        width:"100%",
        // backgroundColor:"violet",
        },
}))

const AboutImgOne = styled("img")(()=> ({
    height:"90%", 
    width:"85%", 
    borderRadius:"10px",
    transition:"O.3s ease-in-out",
    '&:hover': {
        // WebkitTransform:"scale(1.3)",
        // transform:"scale(1.3)",
        // transition:"O.3s ease-in-out",

      },
}))

const AboutTitle = styled(Typography)(()=> ({
   borderLeft:"2px solid black",
   padding:"5px",
   fontFamily:"PoppinsRegular, sans-serif !important"

}))
const AboutTextFirst = styled(Typography)(({theme})=> ({
    lineHeight:"1.0em",
    fontSize:"2.2em",
    fontWeight:"bold",
    fontFamily:"PoppinsBold, sans-serif !important",
    width:"90%",

    '& .decorateText': {
        lineHeight:"1.0em",
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",
        [theme.breakpoints.down('lg')]: {
            // fontSize:"2.2em",
            lineHeight:1.2,
            // backgroundColor:"red"
            },

      },    
      [theme.breakpoints.down('lg')]: {
        fontSize:"2.2em",
        lineHeight:1.2,
        },
      [theme.breakpoints.down('md')]: {
        fontSize:"1.8em",
        },


}))
const AboutTextScnd = styled(Typography)(()=> ({
    color:"transparent",
    WebkitTextStroke:"1px black",
    WebkitTextFillColor:"transparent",
    fontWeight:800,
    fontSize:"32px",
    fontFamily:"PoppinsBold, sans-serif !important"

    // marginTop:"20px",
    // marginBottom:"40px"
}))
const AboutTextDesc = styled(Typography)(({theme})=> ({
    width:"90%",
    fontSize:"15px",
    marginTop:"20px",
    fontFamily:"PoppinsRegular, sans-serif !important",
    [theme.breakpoints.down('lg')]: {
        marginTop:"10px",
        width:"98%",
        fontSize:"1.0em",
    },
    [theme.breakpoints.down('md')]: {
        fontSize:"0.9em",
    },

}))
const ListeStyle = styled(List)(({theme})=> ({
    marginTop:"12px", 
    marginBottom:"12px",
    [theme.breakpoints.down('lg')]: {
        marginTop:"10px", 
        marginBottom:"10px",
    },

}))

const DivButton = styled(Link)(()=>({
    textDecoration:"none",
    }))

function HomeAbout() {
    return (
        <AboutHeroContainer>
            <SectionContaint>
                <AboutHeroLeft>
                    <Grid container >  
                        <Grid item xs={12} sx={{display:"grid", placeItems:"center"}}>
                        {/* <Fade variants={FadeVariants({durationIn: 1.5}).inDown}> */}
                            <AboutImgOne src='assets/images/kii.jpg'/>
                            {/* <AboutImgOne src='assets/images/12.jpg'/> */}
                        {/* </Fade> */}
                        </Grid>
                        <Grid item xs={6} sx={{display:"grid", placeItems:"center"}}>
                        {/* <Fade variants={FadeVariants({durationIn: 1.5}).inLeft}> */}
                            <ImageComponent image={'assets/images/abt.jpg'}  sx={{height:"90%", width:"85%", borderRadius:"10px"}}/>
                        {/* </Fade> */}
                        </Grid>
                        <Grid item xs={6} sx={{display:"grid", placeItems:"center"}}>
                        {/* <Fade variants={FadeVariants({durationIn: 1.5}).inRight}> */}
                            {/* <ImageComponent image={'assets/images/aboutThird.jpg'}  sx={{height:"90%", width:"85%", borderRadius:"10px"}}/> */}
                            <ImageComponent image={'assets/images/des.png'}  sx={{height:"90%", width:"85%", borderRadius:"10px"}}/>
                        {/* </Fade> */}
                        </Grid>
                    </Grid>
                </AboutHeroLeft>
                <AboutHeroRight>
                <AboutTitle>
                    A Propos De Nous
                </AboutTitle>
                <AboutTextFirst>
                    Des capacités illimitées pour des <Typography className='decorateText' component={"span"}>projets extraordinaires </Typography>
                </AboutTextFirst>
                {/* <AboutTextDesc>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum consectetur quia ipsam impedit sapiente cum officia velit, aliquam nulla? Aspernatur doloremque nesciunt velit quisquam labore rerum nihil. Voluptatem assumenda ratione id dolor unde adipisci nulla esse odit. Quis, beatae esse!
                </AboutTextDesc> */}
                <AboutTextDesc sx={{marginBottom:"20px"}}>
                    Nous sommes une agence spécialisée dans les tests logiciels et dans le développement web. Tous les jours nous aidons nos clients dans tout le monde entier à améliorer leur présence ou à s’affirmer sur le net. Nous sommes également des professionnels du marketing digital et du référencement. Nous créondes expériences exceptionnelles et transformons les idées en réalité.
                </AboutTextDesc>
                {/* <AboutTextDesc> */}
                {/* Nous sommes la première entreprise de services numériques au Togo spécialisée dans les tests logiciels. */}
                {/* </AboutTextDesc> */}
                {/* <AboutTextDesc>
                Nous avons su faire  notre place parmi les entreprises des services numeriques specialisés dans les test logiciels.
                </AboutTextDesc> */}
                {/* <ListeStyle sx={{}}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <AboutListItem text={"Expertise"}/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <AboutListItem text={"Ouverture"}/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <AboutListItem text={"Accompagnement"}/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <AboutListItem text={"Responsabilité"}/>
                        </Grid>
                    </Grid>
                </ListeStyle> */}
                <DivButton to={USER_PAGES.about}>
                <AboutStyledButton content={'A Propos'} />
                </DivButton>
                {/* <Button href={USER_PAGES.policy}>confidentialité</Button> */}
                </AboutHeroRight>
            </SectionContaint>
        </AboutHeroContainer>
    );
}

export default HomeAbout;