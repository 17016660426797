import { Button, Card, Typography, Zoom, styled } from '@mui/material';
import React from 'react';
import ImageComponent from './ImageComponent';
import ButtonStyled from './ButtonStyled';
import { Link } from 'react-router-dom';
import { USER_PAGES } from '../routes/path';
import { useTranslation } from 'react-i18next';

const ServiceCardContaint = styled("div")(()=>({
//    border:"3px solid red",
width:"95%",
   transition:"all 0.3s ease",
    '&:hover': {
        transform: "translateY(-10px)",
      },
    // position:"relative"
}))
const ServiceCardStyle = styled(Card)(()=>({
    // width:"400px",
    width:"100%",
    minHeight:"275px",
    backgroundColor:"#111111",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    // alignItems:"center",
    paddingTop:"8%"
    // '&:hover': {
    //     transform: "translateY(-10px)",
    //   },
    // position:"relative"
}))
const ServiceCardIconContainer = styled("div")(()=>({
    width:"90px",
    height:"90px",
    borderRadius:"50%",
    backgroundColor:"white",
    position:"absolute",
    boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    // top:"0px",
    // marginBottom:"50px",
    // border:"1px solid black",
    marginTop:"-50px",
    marginLeft:"25px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
}))
const ServiceCardTitle = styled(Typography)(()=>({
    fontSize:"1.0rem",
    // fontWeight:"bold",
    color:"#CFCFCF",
    marginLeft:"20px",
    fontFamily:"PoppinsLight, sans-serif !important",

    }))
const DivButton = styled(Link)(()=>({
    textDecoration:"none",
    }))

const ServiceCardDesc = styled(Typography)(()=>({
    fontSize:"0.9rem",
    color:"#CFCFCF",
    width:"90%",
    marginLeft:"20px",
    marginTop:"20px",
    marginBottom:"20px",
    fontFamily:"PoppinsRegular, sans-serif !important",

    }))

    const ServiceButtonStyled = styled (Button)(()=>({
        padding: '0.3em 1.3em',
        width:"130px",
        marginLeft:"20px",
        border: '2px solid white',
        borderRadius:"5px",
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: '16px',
        transition: '.3s',
        zIndex: 1,
        fontFamily: 'inherit',
        color: 'white',
        '&::before': {
            content: '""',
            width: 0,
            height: '370%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(45deg)',
            background: 'white',
            transition: '.5s ease',
            display: 'block',
            zIndex: -1,
          },
          '&:hover::before': {
            width: '105%',
          },
          '&:hover': {
            color: '#111',
          },
    }))

    const ButtonText = styled(Typography)(()=>({
        fontFamily:"PoppinsRegular, sans-serif !important",
        transition:"0.3s",
        fontSize:"0.9em",
        '&:hover': {
            // transform:"scale(1.1)"
          },
        }))
    interface Props {
        titre:string,
        description:string,
        image:string,
    }
function ServiceCard({description,image,titre}: Props) {  
    const  {t} = useTranslation();

    return (
        // <Zoom in={true} style={{ transitionDelay: '2000ms'}} sx={{}}>
            <ServiceCardContaint>
            <ServiceCardIconContainer>
                <ImageComponent image={image}/>
            </ServiceCardIconContainer>
            <ServiceCardStyle>
                <ServiceCardTitle>
                    {t(titre)}
                </ServiceCardTitle>
                <ServiceCardDesc>
                    {description}
                </ServiceCardDesc>
                {/* <ButtonStyled content={'Voir...'}/> */}
                <DivButton to={USER_PAGES.service}>
                <ServiceButtonStyled><ButtonText>Lire Plus...</ButtonText></ServiceButtonStyled>
                </DivButton>
            </ServiceCardStyle>
        </ServiceCardContaint>
        // </Zoom>
    );
}

export default ServiceCard;