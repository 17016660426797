import { Box, Container, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import BlogOverviewCard from '../../components/BlogOverviewCard';
import { BlogsData } from '../../common/Data';

const BlogOverviewStyle = styled("section")(()=> ({
    minHeight:"50vh",
    // width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:"white",
    // paddingBottom:"30px",
}))
export const BlogOverviewContaintStyle = styled("div")(({theme})=> ({
    // minHeight:"0vh",
    width:"70vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"10vh 0vh 10vh 0vh ",
    // marginBottom:"25px",
    // backgroundColor:"yellow",
    [theme.breakpoints.down('lg')]: {
        // backgroundColor:"red",
        width:"90%",
        // padding:"20px 0px 20px 0px"
        },
    [theme.breakpoints.down('md')]: {
        // flexDirection:"column",
        // backgroundColor:"red",
        width:"95%",
        // padding:"10px 0px 10px 0px"
        },
}))

const BlogTitleStyled = styled(Typography)(()=> ({
    color:"transparent",
    WebkitTextStroke:"1px black",
    WebkitTextFillColor:"transparent",
    fontWeight:800,
    fontSize:"32px",
    fontFamily:"PoppinsRegular, sans-serif !important",

}))
const BlogTitleSimple = styled(Typography)(({theme})=> ({
    fontSize:"2.0rem",
    fontWeight:"bold",
    fontFamily:"PoppinsRegular, sans-serif !important",
    margin:"0px 0px 50px 0px",
    textAlign:"center",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },
    [theme.breakpoints.down('md')]: {
        fontSize:"1.8rem",
        },

}))
const GridStyle = styled(Grid)(()=> ({
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"center",
    // alignItems:"center",
    display:"grid",
    placeItems:"center",
    // backgroundColor:"greenyellow",
}))

function BlogOverview() {
    const firstThreeItems = BlogsData.slice(0, 3);

    return (
        <BlogOverviewStyle>
            <BlogOverviewContaintStyle>
            <BlogTitleSimple>Nos Dernières <Typography className='decorateText' component={"span"}>Nouvelles </Typography> </BlogTitleSimple>
            {/* <Container> */}
            {/* <Grid container rowSpacing={3} columnSpacing={3}> */}
                <Grid container rowSpacing={3} columnSpacing={3}>
                    {
                        firstThreeItems.map((oneBlog) => (<GridStyle item xs={12} md={6} sm={6} lg={4} sx={{display:"grid", placeItems:"center"}}><BlogOverviewCard image={oneBlog.photoCard} title={oneBlog.titre} date={oneBlog.date} id={oneBlog.id}/></GridStyle>))
                    }
                </Grid>
            {/* </Grid> */}
            {/* </Container> */}
            </BlogOverviewContaintStyle>
        </BlogOverviewStyle>
    );
}

export default BlogOverview;