import React, { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { USER_PAGES } from './path';
import OthersLayout from '../layout/OthersLayout';
import UserLayout from '../layout/UserLayout';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import ServicePage from '../pages/ServicePage';
import Portfolio from '../pages/Portfolio';
import Contact from '../pages/Contact';
import BlogPage from '../pages/BlogPage';
import BlogDetails from '../pages/BlogDetails';
import Confidentialite from '../pages/Confidentialite';
import Mentions from '../pages/Mentions';

export default function Router() {

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
      }, [pathname]);
      
    return useRoutes([
        {
            path: "*",
            element: <OthersLayout />,
            children: [
            ],
        },

        {
            path: USER_PAGES.home,
            element: <UserLayout/>,
            children: [
                {element: <HomePage/>, index:true},
                {path: USER_PAGES.about, element: <AboutPage/>},
                {path: USER_PAGES.service, element: <ServicePage/>},
                {path: USER_PAGES.portfolio, element: <Portfolio/>},
                {path: USER_PAGES.contact, element: <Contact/>},
                {path: USER_PAGES.blogs, element: <BlogPage/>},
                {path: USER_PAGES.blogsDetail, element: <BlogDetails/>},
                {path: USER_PAGES.policy, element: <Confidentialite/>},
                {path: USER_PAGES.mentions, element: <Mentions/>},


            ]
        },

        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}

