import { CalendarMonth } from '@mui/icons-material';
import { Avatar, Box, Divider, Grid, Icon, List, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import BlogListItem from '../../components/BlogListItem';
import ContactForm from '../../components/ContactForm';
import CommentForm from '../../components/CommentForm';
import { BlogModel } from '../../models/BlogModel';

const BlogMainSectionStyle = styled("section")(()=> ({
    minHeight:"200vh",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
}))
const BlogMainImg = styled("img")(()=> ({
    width:"100%",
    height:"60vh",
    objectFit:"cover",
    borderRadius:"10px"
}))
const BlogMainTitle = styled(Typography)(()=> ({
    fontSize:"2.0em",
    fontFamily:"PoppinsBold, sans-serif !important",
    marginTop:"30px",
    marginBottom:"50px",

}))
const BlogMainSubTitle = styled(Typography)(()=> ({
    fontSize:"1.8em",
    fontFamily:"PoppinsBold, sans-serif !important",
    marginTop:"30px",
    // marginBottom:"50px",

}))
const BlogMainDate = styled(Typography)(()=> ({
    fontSize:"1.0em",
    fontFamily:"PoppinsLight, sans-serif !important",

}))
const BlogAuthName = styled(Typography)(()=> ({
    fontSize:"1.3em",
    fontFamily:"PoppinsLight, sans-serif !important",
    '& .decorateText': {
        fontSize:"0.9em",
        fontFamily:"PoppinsRegular, sans-serif !important",

      },

}))
const BlogLongText = styled(Typography)(()=> ({
    fontSize:"1.0em",
    fontFamily:"PoppinsRegular, sans-serif !important",
    textAlign:"justify",
    marginTop:"20px",
    marginBottom:"20px"

}))

export const SectionContaint = styled("div")(({theme})=> ({
    // minHeight:"500px",
    minHeight:"200vh",
    width:"55%",
    display:"flex",
    flexDirection:"column",
    // justifyContent:"center",
    // alignItems:"center",
    // backgroundColor:"brown",
    padding:"10vh 0vh 10vh 0vh",
    [theme.breakpoints.down('lg')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))

const AboutTextFirst = styled(Typography)(({theme})=> ({
    fontSize:"2.0em",
    fontWeight:"bold",
    fontFamily:"PoppinsBold, sans-serif !important",
    textAlign:"center",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px black",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },    
      [theme.breakpoints.down('md')]: {
        fontSize:"1.8em",
        },


}))

interface Props {
    article?: BlogModel,
}

function BlogMainSection({article}:Props) {
    return (
        <BlogMainSectionStyle>
            <SectionContaint>
                {/* <BlogMainImg src='/assets/images/blogProp.jpg'/> */}
                <BlogMainImg src={article?.imageDetailFirst}/>
                {/* <BlogMainTitle>Sentry For Javascript monitors release Apllication Optimized</BlogMainTitle> */}
                <BlogMainTitle>{article?.titre}</BlogMainTitle>
                <Grid container sx={{marginBottom:"30px"}}>
                    <Grid item xs={12} md={10} sm={12} lg={10} sx={{}} >
                        <Stack direction={'row'} sx={{display:"flex", flexDirection:"row", alignItems:"center"}} spacing={2}>
                            {/* <Avatar src='assets/images/testimonyImg.jpg' sx={{width:75, height:75}}/> */}
                            <Avatar src={article?.photoAut} sx={{width:75, height:75}}/>
                            {/* <BlogAuthName>par <Typography className='decorateText' component={"span"}> D.Luffy</Typography></BlogAuthName> */}
                            <BlogAuthName>par <Typography className='decorateText' component={"span"}>{article?.nomAut}</Typography></BlogAuthName>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} sm={12} lg={2} sx={{ display:"grid", placeItems:"center"}}>
                        <Stack direction={'row'} sx={{display:"flex", flexDirection:"row", alignItems:"center"}} spacing={1}>
                           <Icon><CalendarMonth/></Icon>
                            {/* <BlogMainDate>03/10/2022</BlogMainDate> */}
                            <BlogMainDate>{article?.date}</BlogMainDate>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider/>
                {/* <BlogLongText>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi doloribus laboriosam, eligendi dolorem quasi nemo quod libero voluptatibus culpa sint temporibus sit dicta eum tenetur id labore accusamus suscipit atque repellat magnam ullam maiores. Labore tempore itaque, odio officiis porro tenetur, fugiat in a ullam, deserunt ipsa molestias dolores. Debitis tempore, vitae earum delectus nam eum? Magnam repellendus corporis cum nesciunt at, quo consectetur qui nemo quam molestias ex deserunt similique architecto. Quis quia beatae aliquam! Accusamus ea sunt distinctio officia iusto autem voluptatem et perspiciatis quas illo voluptate saepe modi repudiandae incidunt nobis consequuntur, recusandae expedita, molestiae fugiat dolorum atque adipisci id doloremque dolores. Omnis illum libero iure, corrupti distinctio placeat deserunt temporibus quibusdam nesciunt velit quae! Non hic cum dolores accusamus culpa ut repudiandae in nisi eum dolorum. Possimus eveniet sapiente aspernatur nam. Quibusdam atque molestiae accusantium provident. Distinctio ipsam, atque nobis neque laboriosam perspiciatis eligendi repellat nihil.</BlogLongText>
                <BlogLongText>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste autem accusantium neque, necessitatibus reprehenderit magni velit quasi dolore in, assumenda reiciendis earum optio perferendis facere? Error consequuntur non quasi cum tempore, ad incidunt maxime! Reprehenderit modi tempore harum laudantium dicta amet a! Est quis rerum sapiente hic cum impedit atque repellat, veritatis dignissimos sunt obcaecati molestiae accusantium ea nemo itaque aliquam aperiam sequi non blanditiis. </BlogLongText> */}
                {/* <BlogLongText>{article?.contenu}</BlogLongText> */}
                {article?.contenu.map((m, index) => 
                    <BlogLongText>{m}</BlogLongText>
                )}
                {/* <BlogMainImg src='assets/images/ts.webp'/> */}
                <BlogMainImg src={article?.imageDetailSecond}/>
                {/* <BlogMainSubTitle>TypeScript Doesn't Suck; You Just Don't Care About Security:</BlogMainSubTitle> */}
                {/* <List>
                    <BlogListItem text={'TypeScript takes more time to learn than JavaScript.'}/>
                    <BlogListItem text={'TypeScript prevented my API from sending an object without all the suitable properties.'}/>
                    <BlogListItem text={'There is no NEED for types/strict typing.'}/>
                    <BlogListItem text={'Dynamic Typing is not a problem.'}/>
                </List> */}
                {/* <BlogLongText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti ducimus autem nihil aliquam officiis expedita cupiditate libero deserunt exercitationem dignissimos!</BlogLongText> */}
                {/* <BlogLongText>{article?.contenuSecond}</BlogLongText> */}
                {article?.contenuSecond.map((m, index) => 
                    <BlogLongText>{m}</BlogLongText>
                )}
                <Divider sx={{margin:"8vh 0vh 8vh 0vh"}}/>
                <Box sx={{ display:"grid", placeItems:"center"}}>
                    <AboutTextFirst>
                        Laissez un <Typography className='decorateText' component={"span"}>commentaire </Typography>
                    </AboutTextFirst>
                        <CommentForm/>
                </Box>
            </SectionContaint>
        </BlogMainSectionStyle>
    );
}

export default BlogMainSection;