import { Box, styled } from '@mui/material';
import React from 'react';
import SocialMediaItem from './SocialMediaItem';
import { Email, Facebook, Instagram, LinkedIn, Twitter, WhatsApp } from '@mui/icons-material';

const SocialMediaMenuStyle= styled (Box)(()=>({
    display:"flex",
    gap:10,
    marginRight:"3.5em"
    // backgroundColor:"red",
}))


function SocialMediaMenu() {
    return (
        <SocialMediaMenuStyle>
            <SocialMediaItem children={<Facebook />} path={"https://www.facebook.com/people/Kodinart/100093572302096/?notif_id=1687741602744143&notif_t=page_user_activity&ref=notif"}/>
            <SocialMediaItem children={<Instagram/>} path={'https://www.instagram.com/kodinart/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'}/>
            <SocialMediaItem children={<WhatsApp/>} path={'https://wa.me/22890066593'}/>
            <SocialMediaItem children={<LinkedIn/>} path={''}/>
        </SocialMediaMenuStyle>
    );
}

export default SocialMediaMenu;