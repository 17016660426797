import React from 'react';
import SectionType from '../components/SectionType';
import AboutDetailsSection from '../sections/AboutPage/AboutDetailsSection';
import TeamSection from '../sections/AboutPage/TeamSection';
import Page from '../components/Pages';
import QualitieSection from '../sections/AboutPage/QualitieSection';
import {Helmet} from "react-helmet";

function AboutPage() {
    return (
            <Page  title={'A Propos'}>                
                <Helmet>
                    <meta name="author" content="Kodinart"/>
                    <meta name="description" content="Découvrez KODINART,une agence informatique spécialisée dans le  QA & testing, le developpement Web et Mobile, et UI/UX Design. 
                                                    Nous avons su faire notre place parmi les entreprises des services numeriques specialisés dans les test logiciels." 
                    />
                    <meta name="keywords" content="services informatiques KODINART, Expertise, Accompagnement, Ouverture, Responsabilité, développement web, QA & testing, UI/UX Design" />
                </Helmet>
                <SectionType pageTitle={'A Propos'}/>
                <AboutDetailsSection/>
                <QualitieSection/>
                <TeamSection/>
            </Page>
    );
}

export default AboutPage;