import { Typography, styled } from '@mui/material';
import React from 'react';
import TestComponent from '../../components/TestComponent';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "swiper/css/navigation";
// import 'swiper/swiper.css';
import 'swiper/css'

const TestimonySwipeStyle = styled("section")(()=> ({
    minHeight:"2S0vh",
    // width:"80%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    position:"relative",
    '&::before': {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage:"url(assets/images/stars.jpeg)",// Ajoutez le chemin de votre image de masque
        backgroundSize: "cover",
        backgroundPosition: "center",
        maskImage: "none", // Empêche le masque de se superposer sur l'élément masqué
        opacity: 0.2, // Définissez l'opacité pour ajuster l'intensité de l'effet de masque
        zIndex: -1, // Mettez l'élément masque en arrière-plan pour qu'il n'interfère pas avec le contenu de votre composant
      },
    // backgroundImage:"url(assets/images/stars.jpeg)",
    backgroundSize:"cover",
    backgroundPosition:"center",
}))
const SwipeContent = styled("div")(({theme})=> ({
    // minHeight:"40vh",
    width:"70vw",
    // display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"8vh 0vh 8vh 0vh ",
    // backgroundColor:"red",
    [theme.breakpoints.down('sm')]: {
        width:"100%",
    },
}))
const CarouselContaint = styled("div")(()=> ({
    // minHeight:"80vh",
    // backgroundColor:"orchid",
    // width:"30%"
}))



function TestimonySwipSection() {
    return (
        <TestimonySwipeStyle>
            <SwipeContent>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <TestComponent text={"J'ai fait appel à cette agence informatique pour la création d'un site web pour mon entreprise et je suis extrêmement satisfaite du résultat. L'équipe et leur support technique est excellent.  Je recommande vivement leurs services à tous ceux qui recherchent des solutions informatiques fiables et professionnelles."} nomAut={'Sophie DELARUCHE'} image={''}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <TestComponent text={"J'ai fait appel à cette agence informatique pour la création d'un site web pour mon entreprise et je suis extrêmement satisfaite du résultat. L'équipe et leur support technique est excellent.  Je recommande vivement leurs services à tous ceux qui recherchent des solutions informatiques fiables et professionnelles."} nomAut={'Sophie DELARUCHE'} image={''}/>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <TestComponent text={"J'ai eu recours aux services de cette agence informatique pour la mise en place d'un système de sécurité informatique pour mon entreprise. Je suis très satisfait du travail accompli. L'équipe a pris le temps d'analyser nos besoins spécifiques et a mis en place des mesures de sécurité solides qui ont grandement renforcé notre protection. Ils ont également fourni une formation approfondie à notre personnel pour maximiser l'efficacité de ces mesures. Je suis impressionné par leur expertise et leur professionnalisme, et je les recommande sans hésitation."} nomAut={'Sophie DELARUCHE'} image={''}/>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                        <TestComponent text={"Je tenais à exprimer ma satisfaction envers cette agence informatique qui a réalisé le développement d'une application web pour mon entreprise. L'équipe a été très à l'écoute de mes besoins et a su créer une application qui répond parfaitement aux attentes de mes clients. La qualité du code, la convivialité de l'interface utilisateur et la rapidité de l'application sont tout simplement remarquables."} nomAut={'Thomas BILL'} image={''}/>
                    </SwiperSlide> */}
                </Swiper>
            </SwipeContent>
        </TestimonySwipeStyle>
    );
}

export default TestimonySwipSection;