import { Card, Container, Grid, Pagination, Paper, styled } from '@mui/material';
import React, { useState } from 'react';
import { BlogsData } from '../common/Data';
import BlogOverviewCard from './BlogOverviewCard';

const BlogGridContent = styled("div")(({theme})=>({
    minHeight:"500px",
    width:"80%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"red",
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))

export const SectionContaint = styled("div")(({theme})=> ({

}))

function BlogsGrid() {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(BlogsData.length / 6));
    const startIndex = (page - 1) * 6;
    const endIndex = startIndex + 6;
    const currentBlogs = BlogsData.slice(startIndex, endIndex);
    return (
        <BlogGridContent>
            <Container>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    {
                        currentBlogs.map((oneBlog) => (<Grid item xs={12} md={6} sm={6} lg={4} sx={{display:"grid", placeItems:"center"}}><BlogOverviewCard image={oneBlog.photoCard} title={oneBlog.titre} date={oneBlog.date} id={oneBlog.id}/></Grid>))
                    }
                </Grid>
            </Container>
            <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => {setPage(value)}}
            sx={{margin:"30px 0px 30px 0px"}}
            variant='outlined'
            shape='rounded'
            />
        </BlogGridContent>
    );
}

export default BlogsGrid;