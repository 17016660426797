import {  styled, SxProps } from '@mui/material';
import FadeVariants from '../variants/FadeVariants';
import Fade from '../animations/Fade';


const ImageComponentContainer= styled("img")(()=>({
    
}));
interface Props {
    image: string,
    sx?: SxProps
}
function ImageComponent({image, sx=  {}}: Props) {
    return (
        // <Fade variants={FadeVariants({durationIn: 1.5}).inUp}>
            // <Fade in={true} timeout={3000}>
                <ImageComponentContainer sx={{...sx}} src={image}/>
            // </Fade>
        // {/* </Fade> */}
    );
}

export default ImageComponent;