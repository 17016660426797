import { DateRangeRounded, Launch } from '@mui/icons-material';
import { Button, Card, CardActionArea, CardContent, CardMedia, Chip, Icon, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { USER_PAGES } from '../routes/path';

const BlogOverviewCardStyle = styled(Card)(()=>({
    // width:"350px",
    width:"98%",
    minHeight:"400px",
    backgroundColor:"white",
    borderRadius:"12px",
    // margin:"22px",
    boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px",
    transition: "all ease 300ms",
    '&:hover':{
        transform: "scale(1.04)"
    },
}))

const BlogCardButton = styled(Button)(()=>({
    color:"black",
    fontSize:"15px",
    fontFamily:"PoppinsRegular, sans-serif !important",
    top:20,
    marginBottom:"10px",
    border:"2px solid",
    // borderBottom:"2px solid",
    textTransform:"capitalize",
    '&:hover': {
        opacity: 1,
      },
    }))
const BlogCardTitle = styled(Typography)(()=>({
    fontFamily:"MontseratBold, sans-serif !important",
    marginBottom:"10px"

    }))
const BlogCardDate = styled(Typography)(()=>({
    fontFamily:"PoppinsRegular, sans-serif !important",

    }))

interface Props {
    image: string,
    title: string,
    date: string,
    id: string
}

function BlogOverviewCard({image, title, date, id}:Props) {
    const naviguate = useNavigate()

    const goToBlogDetails = (idDetails: string) => {
        naviguate(`/blogsDetail/${idDetails}`)
    }
    return (
        <BlogOverviewCardStyle onClick={()=> goToBlogDetails(id)}>
                <CardMedia component={"img"} image={image}/>
                <CardContent>
                    <BlogCardTitle variant="body1" sx={{ }}>{title}</BlogCardTitle>
                    <Stack direction={"row"} spacing={2}><Icon children={<DateRangeRounded/>} /><BlogCardDate>{date}</BlogCardDate></Stack>
                    {/* <BlogCardButton href={USER_PAGES.blogsDetail} endIcon={<Launch/>}>Lire Plus</BlogCardButton> */}
                    <BlogCardButton  endIcon={<Launch/>}>Lire Plus</BlogCardButton>
                </CardContent>
        </BlogOverviewCardStyle>
    );
}

export default BlogOverviewCard;