import { Container, Divider, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import TeamMember from '../../components/TeamMember';
import OneMember from '../../components/OneMember';

const TeamSectionStyle = styled("section")(()=> ({
    minHeight:"50vh",

    backgroundColor: "black",
    overflow: "hidden",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
}))
const GridContent = styled(Grid)(()=> ({
    display:"grid",
    placeItems:"center"
}))
export const SectionContaint = styled("div")(({theme})=> ({
    // minHeight:"500px",
    width:"70vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"green",
    padding:"10vh 0vh 10vh 0vh ",

    [theme.breakpoints.down('lg')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('md')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))

const AboutTitle = styled(Typography)(()=> ({
    borderLeft:"2px solid black",
    padding:"5px",
    fontFamily:"PoppinsRegular, sans-serif !important"
 
 }))

 const TeamTextFirst = styled(Typography)(({theme})=> ({
    fontSize:"3.0em",
    fontWeight:"bold",
    fontFamily:"PoppinsBold, sans-serif !important",
    color:"white",
    margin:"0px 0px 25px 0px",
    '& .decorateText': {
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px white",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",

      },    
      [theme.breakpoints.down('md')]: {
        fontSize:"1.6em",
        },
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.3em",
        },


}))

const ServiceSectionSubTitle = styled(Typography)(({theme})=> ({
    fontSize:"1.1rem",
    color:"white",
    maxWidth:"45%",
    textAlign:"center",
    // marginTop:"2%",
    marginBottom:"50px",
    fontFamily:"PoppinsLight, sans-serif !important",
    [theme.breakpoints.down('md')]: {
        maxWidth:"90%",
        fontSize:"1.0rem",
        marginTop:"2%",
        marginBottom:"75px",
        // flexDirection:"column",
        // backgroundColor:"red",
        // width:"90%",
        },
}))



function TeamSection() {
    return (
        <TeamSectionStyle>
        <SectionContaint>
            <TeamTextFirst>
                Notre Team <Typography className='decorateText' component={"span"}>de Professionels </Typography>
            </TeamTextFirst>
            <ServiceSectionSubTitle>
                Nos ingénieurs concoivent avec la dernière tendance du design et codent dans des approches modernes et de sécurité
            </ServiceSectionSubTitle>
                {/* <Container> */}
                <Grid container columnSpacing={3} rowSpacing={3} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <GridContent item xs={12} md={6} sm={6} lg={3}>
                            <OneMember image={"assets/images/devillus.jpg"} nom={"3 développeurs"} post={''}/>
                        </GridContent>
                        <GridContent item xs={12} md={6} sm={6} lg={3}>
                            <OneMember  image={"assets/images/markillus.jpg"} nom={"1 community manager"} post={""}/>
                        </GridContent>
                        <GridContent item xs={12} md={6} sm={6} lg={3}>
                            <OneMember  image={"assets/images/testillus.jpg"} nom={"1 testeur QA"} post={''}/>
                        </GridContent>
                        {/* <GridContent item xs={12} md={6} sm={6} lg={3}>
                            <OneMember  image={"assets/images/team.jpg"} nom={"Harry CODEMAN"} post={"Developeur Mobile"}/>
                        </GridContent> */}
                    </Grid>
                {/* </Container> */}
                {/* <Divider  sx={{backgroundColor:"white",  width:"100%"}}/> */}
            </SectionContaint>
        {/* <Divider sx={{backgroundColor:"red"}}/> */}
        </TeamSectionStyle>
    );
}

export default TeamSection;