import { Adjust, ArrowRight } from '@mui/icons-material';
import { Stack, styled, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const FooterItemContainer = styled('div')(() => ({
    marginTop:"20px"
}));
const FooterItemLink = styled(Link)(() => ({
    textDecoration: "none",
    color:"white",
    margin:"10px",
}));

interface Pops {
    title: string,
    path: string,
    sx? : SxProps,
    children?: ReactNode,
};

function FooterItems({title, path, sx={}, children}: Pops) {
    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <FooterItemContainer>
            <Stack direction={'row'} spacing={1}>
                {children}
            <Typography  component={FooterItemLink} to={path} variant="body1" style={{color: match ? "white" : "white",}} sx={{ fontFamily:"PoppinsLight, sans-serif !important",...sx}}>{title}</Typography>
            </Stack>
        </FooterItemContainer>
    );
}

export default FooterItems;