import { Typography, styled } from '@mui/material';
import React from 'react';
import Fade from '../animations/Fade';
import FadeVariants from '../variants/FadeVariants';

const HeroTextOne = styled(Typography)(({theme})=>({
    // color:"#F8F8F8", 
    color:"whitesmoke", 
    // fontWeight:"bold",
    width:"65%",
    fontSize:"3.2em",
    lineHeight:"1.0em",
    // opacity:0,
    // WebkitTransform:"translateY(100px)",
    // WebkitTransition:"all 500ms ease",
    // marginBottom:"2vh",
    // fontSize:"1.3em",
    // transition: 'blue 0.5s ease-in-out',
    fontFamily:"PoppinsBold, sans-serif !important",
    '&.textTransition' : {
        transition: '1s ease-out',
        // color:"red",
        opacity: 1,
        WebkitTransform:" translateY(0px)",
    },
    [theme.breakpoints.down('lg')]: {
        fontSize:"4.0em",
        width:"95%",
        lineHeight:"1.0em",
    },
    [theme.breakpoints.down('md')]: {
        fontSize:"3.5em",
        width:"95%",
        textAlign:'center',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize:"2.5em",
        width:"100%",
        textAlign:'center',
        lineHeight:"1.0em",
    }, 

    
}))

interface Props {
    text:string,
    aSlide?:number,
}

function SlideTextOne({text, aSlide}: Props) {
    return (
        <Fade variants={FadeVariants({durationIn: 1.5}).inDown}>
        {/* <HeroTextOne className={aSlide ? "textTransition" : ""}> */}
        <HeroTextOne >
            {text}
        </HeroTextOne>
        </Fade>
    );
}

export default SlideTextOne;