import { Button, Typography, styled } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { USER_PAGES } from '../routes/path';

const ButtonStyledStyle = styled (Button)(()=>({
    padding: '0.5em 1.5em',
    width:"150px",
    border: '2px solid white',
    borderRadius:"5px",
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    transition: '.3s',
    zIndex: 1,
    fontFamily: 'inherit',
    color: 'white',
    '&::before': {
        content: '""',
        width: 0,
        height: '325%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        background: 'white',
        transition: '.5s ease',
        display: 'block',
        zIndex: -1,
      },
      '&:hover::before': {
        width: '105%',
      },
      '&:hover': {
        color: '#111',
      },
}))

const ButtonText = styled(Typography)(()=>({
  fontFamily:"PoppinsRegular, sans-serif !important",
  transition:"0.3s",
  '&:hover': {
      // transform:"scale(1.1)"
    },
  }));
const DivBut = styled(Link)(()=>({
      textDecoration:"none"
  }))

  interface Props {
    content:string,
    path:string,
    
  }
function ButtonStyled({content, path}:Props) {
    return (
      <DivBut to={path}>
      {/* <Link to={path}> */}
        <ButtonStyledStyle>
          <ButtonText>
            {content}
          </ButtonText>
        </ButtonStyledStyle>
      {/* </Link> */}
      </DivBut>
    );
}

export default ButtonStyled;