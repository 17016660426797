import React from 'react';
import Page from '../components/Pages';
import SectionType from '../components/SectionType';
import ConfidentialiteSection from '../sections/ConfidentialitePage/ConfidentialiteSection';

function Confidentialite() {
    return (
        <Page title='Condidentialite'>
            <SectionType pageTitle={'Politique de confidentialité'}/>
            <ConfidentialiteSection/>
        </Page>
    );
}

export default Confidentialite;