import { Stack, SxProps, Typography, styled } from '@mui/material';
import React, { ReactNode } from 'react';

const FooterItemContainer = styled('div')(() => ({
    marginTop:"20px"
}));
const FooterItemLink = styled("a")(() => ({
    textDecoration: "none",
    color:"white",
    margin:"10px",
}));

interface Pops {
    title: string,
    path: string,
    sx? : SxProps,
    children?: ReactNode,
};

function FooterItemAd({title, path, sx={}, children}: Pops) {
    return (
        <FooterItemContainer>
            <Stack direction={'row'} spacing={1}>
                {children}
            <Typography  component={FooterItemLink} target='_blank' href={path} sx={{ fontFamily:"PoppinsLight, sans-serif !important", ...sx}}>{title}</Typography>
            </Stack>
        </FooterItemContainer>
    );
}

export default FooterItemAd;