import { Container, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import ServiceCard from '../../components/ServiceCard';
import { services } from '../../common/Data';
import Fade from '../../animations/Fade';
import FadeVariants from '../../variants/FadeVariants';

const ServiceSectionStyle = styled("section")(()=> ({
    minHeight:"50vh",
    width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor: "black",
}))

export const SectionContaint = styled("div")(({theme})=> ({
    minHeight:"500px",
    width:"70vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"10vh 0vh 10vh 0vh ",
    // backgroundColor:"red",
    [theme.breakpoints.down('lg')]: {
        flexDirection:"column",
        // backgroundColor:"red",
        width:"90%",
        marginBottom:"15px"
        },
    [theme.breakpoints.down('sm')]: {
        // alignItems:"center",
        // width:"95%",
        // backgroundColor:"blue",
        },
}))
const ServiceSectionTitle = styled(Typography)(({theme})=> ({
    fontSize:"2.2rem",
    fontFamily:"PoppinsBold, sans-serif !important",
    // fontSize:"4vw",
    fontWeight:"bold",
    color:"white",
    textAlign:"center",
    
    [theme.breakpoints.down('md')]: {
        fontSize:"2.0rem",
        },
}))
const ServiceSectionSubTitle = styled(Typography)(({theme})=> ({
    fontSize:"1.1rem",
    color:"white",
    maxWidth:"45%",
    textAlign:"center",
    marginTop:"2%",
    marginBottom:"8%",
    fontFamily:"PoppinsLight, sans-serif !important",
    [theme.breakpoints.down('md')]: {
        maxWidth:"90%",
        fontSize:"1.0rem",
        marginTop:"2%",
        marginBottom:"75px",
        // flexDirection:"column",
        // backgroundColor:"red",
        // width:"90%",
        },
}))

const GridStyle = styled(Grid)(()=> ({
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"flex-end",
    // backgroundColor:"greenyellow"
}))

function ServiceSection() {
    return (
        <ServiceSectionStyle>
            <SectionContaint>
            <Fade  variants={FadeVariants({durationIn:2}).in}>
                <ServiceSectionTitle>
                En quoi pouvons-nous vous assister ?
                </ServiceSectionTitle>
            </Fade>
            {/* <Fade  variants={FadeVariants({durationIn:1.5}).inUp}>
            </Fade> */}
                <ServiceSectionSubTitle>
                Nous offrons notre expertise créative et innovante pour aider les marques haut de gamme à façonner leur avenir. 
                </ServiceSectionSubTitle>
                {/* <ServiceSectionSubTitle>
                Nous offrons notre expertise créative et innovante pour aider les marques haut de gamme à façonner leur avenir. Nous élaborons des idées uniques et éprouvées pour faire croître votre entreprise.
                </ServiceSectionSubTitle> */}
                    <Grid container rowGap={10}>
                        {
                            services.map((oneService, index) => {
                                return <GridStyle item sm={6} xs={12} md={6} lg={6} xl={3}><Fade variants={FadeVariants({durationIn:index * 0.5}).inUp} key={index} sxFade={{display:"grid", placeItems:"center"}}><ServiceCard titre={oneService.title} description={oneService.description} image={oneService.icone}/></Fade></GridStyle>
                            })
                        }
                    </Grid>
            </SectionContaint>
        </ServiceSectionStyle>
    );
}

export default ServiceSection;