import { IconButton, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BackCarousel from '../../components/BackCarousel';
import SlideType from '../../components/SlideType';
import { slides } from '../../common/Data';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const HomeHeroContainer = styled("section")(()=> ({
    minHeight:"100vh",
    width:"100%",
    position: "relative",
    // backgroundColor: "yellow",
    overflow: "hidden",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
}))

const ButtonContainer = styled("div")(()=>({
    width:"90%",

    // backgroundColor:"burlywood",
    // maxHeight:"25px",
    position:"absolute",
    display:"flex",
    justifyContent:"space-between",
    // alignItems:"center",
    // bottom:60,
    // right:0,

}))
const ButtonStyle = styled(IconButton)(()=>({
    color:"white",
    // border:"1px solid",

}))

function HomeHero() {
    const [activeSlide, setActiveSlide] = useState(0);

    const handlePrev = () => {
        setActiveSlide(activeSlide === 0 ? slides.length - 1 : activeSlide - 1);
             };

    const handleNext = () => {
    setActiveSlide(activeSlide === slides.length - 1 ? 0 : activeSlide + 1);
};         

    
useEffect(() => {
    const interval = setInterval(() => {
        handleNext();
    }, 5500); // Change slide every 5 seconds (adjust the duration as needed)

    return () => {
        clearInterval(interval);
    };
}, [activeSlide]);
    return (
        <HomeHeroContainer>
            <BackCarousel>
            {/* {slides.map((slide, index) => (
          <SlideType
            key={index}
            image={slide.backImage}
            firstText={slide.textOne}
            secondText={slide.textTwo}
            pageLink={slide.desc}
            descrip={slide.desc}
            aSlide={activeSlide === index ? 1 : 0}
          />
        ))} */}
                <SlideType image={slides[activeSlide].backImage} firstText={slides[activeSlide].textOne} secondText={slides[activeSlide].textTwo} pageLink={slides[activeSlide].desc} descrip={slides[activeSlide].desc} keyUnique={slides[activeSlide].id} />
            </BackCarousel>
            <ButtonContainer>
                <ButtonStyle onClick={handlePrev}><ArrowBackIosIcon/></ButtonStyle>
                <ButtonStyle onClick={handleNext}><ArrowForwardIosIcon/></ButtonStyle>                
            </ButtonContainer>
        </HomeHeroContainer>
    );
}

export default HomeHero;