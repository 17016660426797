import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CommentModel } from '../models/CommentModel';
import { addComment } from '../redux/slices/Comments';
import { useAppDispatch } from '../redux/store';
import Cookies from 'js-cookie';


const CommentFormContainer = styled ("div")(()=>({
    minWidth:"300px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",
    borderRadius:"5px",
    alignItems:"center",
    marginTop:"20px",
    padding:"10px",
    // opacity:0.8
}))
const ButtonText = styled(Typography)(()=>({
    fontFamily:"PoppinsRegular, sans-serif !important",
    transition:"0.3s",
    '&:hover': {
        // transform:"scale(1.1)"
      },
    }))
    const ContactButtonStyle = styled (Button)(()=>({
        padding: '0.5em 1.5em',
        marginTop:"10px",
        width:"150px",
        border: '2px solid black',
        borderRadius:"5px",
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '16px',
        transition: '.3s',
        zIndex: 1,
        fontFamily: 'inherit',
        color: 'black',
        '&::before': {
            content: '""',
            width: 0,
            height: '325%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(45deg)',
            background: 'black',
            transition: '.5s ease',
            display: 'block',
            zIndex: -1,
          },
          '&:hover::before': {
            width: '105%',
          },
          '&:hover': {
            color: 'white',
          },
      }))

      const DialogTextStyle = styled(DialogContentText)(()=> ({
        fontFamily:"PoppinsRegular, sans-serif !important",
        // minHeight:"80vh",
        // backgroundColor:"orchid",
        // width:"30%"
    }))

function CommentForm() {
    const [data, setData]= React.useState<CommentModel>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const dispatch = useAppDispatch();




    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

        setData((prev) => ({...prev, [e.target.name]: e.target.value} as CommentModel));
        console.log(data);

      };

      const dialogClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setDialogIsOpen(false);
      };

      const resetForm = () => {
        setData({
          name: '',
          email: '',
          message: '',
          uid:'',
        });
      };

      const validate = (event: React.FormEvent) => {
      event.preventDefault();

        if (data) {
            dispatch(addComment({oneAddComment: data})).unwrap().then(() => {
                          setDialogIsOpen(true);
                          resetForm();
                          console.log(data);
            })
        }

        if (data) {
          Cookies.set('commentData', JSON.stringify(data));
        }
      }

      useEffect(() => {
        // Lorsque le composant est monté, essayez de récupérer le cookie et définissez-le dans l'état.
        // const storedUsername = Cookies.get('username');
        const storedData = Cookies.get('commentData');
        if (storedData) {
          // Si le cookie existe, analysez-le pour obtenir l'objet CommentModel et définissez-le dans l'état.
          setData(JSON.parse(storedData));
        }
      }, []);

    return (
      <Box component={"form"} onSubmit={validate}>
        <CommentFormContainer>
            <Container>
              <Typography>HIII{data?.name || 'invité'}</Typography>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                        <TextField name="name" required={true}  variant="outlined" label={"Nom et Prénoms"} type="text" fullWidth sx={{color:"white"}} value={data?.name} onChange={handleChange}/>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                        <TextField name="email" required={true}  variant="outlined" label={"Email"} type="email" fullWidth value={data?.email} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="message" required={true}  variant="outlined" label={"Message"} fullWidth  multiline rows={6} value={data?.message} onChange={handleChange}/>
                    </Grid>
                </Grid>
            </Container>
                <ContactButtonStyle type='submit'><ButtonText>Envoyer</ButtonText></ContactButtonStyle>
                <Dialog open={dialogIsOpen} onClose={dialogClose}>
                    <DialogContent>
                        <DialogTextStyle>Merci d'avoir laisser un commentaire!!</DialogTextStyle>
                        <DialogActions><Button  onClick={dialogClose}>Fermer</Button></DialogActions>
                    </DialogContent>
                </Dialog>
        </CommentFormContainer>
      </Box>
    );
}

export default CommentForm;