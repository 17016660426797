import { styled } from '@mui/material';
import React from 'react';
import MentionsText from '../../components/MentionsText';

const MentionsSectionStyle=styled("section")(({theme})=>({
    width:"calc(100% - 40vw)",
    padding:"75px 20vw 20px 20vw",
    minHeight:"25vh",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    // backgroundColor:"yellow",
    [theme.breakpoints.down("lg")]: {
        // flexDirection:"column",
        width:"calc(100% - 4vw)",
        padding:"75px 2vw 20px 2vw",
        // display:"flex",
        // justifyContent:"center",
        // alignItems:"center",
        
    },
    [theme.breakpoints.down("xs")]: {
        // flexDirection:"column",
        width:"calc(100% - 8vw)",
        padding:"75px 4vw 20px 4vw",
        // display:"flex",
        // justifyContent:"center",
        // alignItems:"center",
        
    },
}));
function MentionsDetaill() {
    return (
        <MentionsSectionStyle>
            <MentionsText/>
        </MentionsSectionStyle>
    );
}

export default MentionsDetaill;