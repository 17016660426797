import { Box, Container, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import ServiceCard from '../../components/ServiceCard';
import { services } from '../../common/Data';
import Fade from '../../animations/Fade';
import { wrap } from "popmotion";
import FadeVariants from '../../variants/FadeVariants';

const SectionPresentationStyle = styled("section")(()=> ({
    minHeight:"20vh",
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"blue"
}));
const SectionPresentationStyleContent = styled("section")(({theme})=> ({
    // minHeight:"90vh",
    width:"70vw",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    padding:"12vh 0vh 10vh 0vh ",
    // backgroundColor:"blue",
    [theme.breakpoints.down('lg')]: {
        width:"95%",
        // paddingTop:"50px"
        },
    [theme.breakpoints.down('md')]: {
        width:"90%",
        flexDirection:"column",
        marginBottom:"15px"
        },
}));

const ServicePresentionLeft = styled("div")(({theme})=> ({
    width:"40%",
    // backgroundColor:"red",
    // minHeight:"85vh",
    display:'flex',
    flexDirection:"column",
    justifyContent:'center',
    alignItems:'flex-start',
    // padding:"3em 0em 0em 0em",
    // backgroundColor:"royalblue",
    [theme.breakpoints.down('md')]: {
        width:"100%",
        // minHeight:"30vh",
        // backgroundColor:"red",
        },
}))
const ServicePresentionRight = styled("div")(({theme})=> ({
    width:"60%",
    // backgroundColor:"violet",
    display:"grid",
    // flexDirection:"column",
    // justifyContent:"center",
    alignItems:"center",
    // minHeight:"85vh",
    // padding:"5em 0em 3em 0em",
    [theme.breakpoints.down('md')]: {
        width:"100%",
        padding:"12vh 0vh 0vh 0vh",
        // minHeight:"30vh",
        // backgroundColor:"yellow",
        },
}))
const ServicePresentionLittleText = styled(Typography)(()=> ({
    fontSize:"0.9em",
    fontFamily:"PoppinsRegular, sans-serif !important",
    // textJustify:"inherit"
    textAlign:"justify"
}))

const ServicePresentionImg = styled("img")(()=> ({
    height:"80%", 
    width:"100%", 
    borderRadius:"10px",
    objectFit:"cover",
    marginBottom:"50px",
    // transition:"O.3s ease-in-out",
    transition:"all 0.3s ease",
        '&:hover': {
        // WebkitTransform:"scale(1.3)",
        transform:"scale(1.05)",
        // transition:"O.3s ease-in-out",

      },
}))

const GridStyle = styled(Grid)(()=> ({
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"flex-end",
    // backgroundColor:"greenyellow"
}))

function ServicePresention() {
    return (
        <SectionPresentationStyle>
            <SectionPresentationStyleContent>
            <ServicePresentionLeft>
                    <ServicePresentionImg src='assets/images/stes.jpg'/>
                    {/* <ServicePresentionLittleText>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo rem recusandae distinctio debitis cupiditate repellat, sed reiciendis, eligendi soluta vero dolorum magnam odit consequatur blanditiis maiores commodi quas nostrum dolorem adipisci officia excepturi nisi molestiae veniam quo. Perspiciatis totam fuga tenetur a, exercitationem laboriosam in, necessitatibus delectus, illo optio maxime?
                    </ServicePresentionLittleText> */}
                    <ServicePresentionLittleText>
                    Notre solution, la croissance de votre entreprise.
                    Nous nous soucions de la croissance pendant la conception de toutes nos solutions que nous proposons à nos clients & nous les accompagnons dans un mode agile.
                    </ServicePresentionLittleText>
            </ServicePresentionLeft>
            <ServicePresentionRight>
                   <Container>
                   {/* <Grid container spacing={{ xs: 1, md: 2 }}  rowSpacing={8}>
                        <Grid item xs={12} lg={6} sm={6} md={6}>
                            <Box><ServiceCard titre={'Innovations'} description={'lorem ipsum dolor lorem ipsum dolorlorem ipsum dolorlorem ipsum dolorlorem ipsum dolor'} image={'assets/images/dev.png'}/></Box>
                        </Grid >
                        <Grid item  xs={12} lg={6} sm={6} md={6}>
                            <Box><ServiceCard titre={'Innovations'} description={'lorem ipsum dolor lorem ipsum dolorlorem ipsum dolorlorem ipsum dolorlorem ipsum dolor'} image={'assets/images/dev.png'}/></Box>
                        </Grid>
                        <Grid item  xs={12} lg={6} sm={6} md={6}>
                            <Box><ServiceCard titre={'Innovations'} description={'lorem ipsum dolor lorem ipsum dolorlorem ipsum dolorlorem ipsum dolorlorem ipsum dolor'} image={'assets/images/dev.png'}/></Box>
                        </Grid>
                        <Grid item  xs={12} lg={6} sm={6} md={6}>
                            <Box><ServiceCard titre={'Innovations'} description={'lorem ipsum dolor lorem ipsum dolorlorem ipsum dolorlorem ipsum dolorlorem ipsum dolor'} image={'assets/images/dev.png'}/></Box>
                        </Grid>
                    </Grid> */}
                    <Grid container rowGap={10}>
                        {
                            services.map((oneService, index) => <GridStyle key={index} item xs={12} lg={6} sm={6} md={6}><Fade  variants={FadeVariants({durationIn:1}).inUp} sxFade={{display:"grid", placeItems:"center"}}><ServiceCard titre={oneService.title} description={oneService.description} image={oneService.icone}/></Fade></GridStyle>)
                        }
                    </Grid>
                   </Container>
            </ServicePresentionRight>
            </SectionPresentationStyleContent>
        </SectionPresentationStyle>
    );
}

export default ServicePresention;