import { styled, SxProps } from '@mui/material';
import '../Styles/index.css'
import { useTranslation } from 'react-i18next';

const ImageComponentContainer= styled("img")(()=>({
    position: "relative",
    display: "inline-block",
    margin: '0 5px'
}));


function LangChange() {
    const { i18n } = useTranslation(); // Accédez à la fonction de traduction via le hook useTranslation
    const handleChangeLanguage = (event: { target: { checked: any; }; }) => {
        const newLanguage = event.target.checked ? "en" : "fr";
        i18n.changeLanguage(newLanguage); // Changez la langue avec la fonction changeLanguage de i18n
    };
    return (
<center>
	<div >
	    <label className='switch'>
	        <input  type="checkbox" onChange={handleChangeLanguage}/>
            <div className="slider">
                <span className="off"><h5>FR</h5></span>
	            <span className="on"><h5>ENG</h5></span>
            </div>
        </label>
	    {/* <label htmlFor="language-toggle">
	        <input id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox"/>
	        <span className="on">BN</span>
	        <span className="off">EN</span>
        </label> */}
  	</div>
 </center>
    );
}

export default LangChange;