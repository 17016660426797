import React from 'react';
import HomeHero from '../sections/HomePage/HomeHero';
import HomeAbout from '../sections/HomePage/HomeAbout';
import ServiceSection from '../sections/HomePage/ServiceSection';
import PrjectsNumber from '../sections/HomePage/PrjectsNumber';
import FolioOverview from '../sections/HomePage/FolioOverview';
import SlideType from '../components/SlideType';
import BlogOverview from '../sections/HomePage/BlogOverview';
import LittleContactSection from '../sections/HomePage/LittleContactSection';
import TestimonySection from '../sections/HomePage/TestimonySection';
import TestimonySwipSection from '../sections/HomePage/TestimonySwipSection';
import HomeHeroSlide from '../sections/HomePage/HomeHeroSlide';
import Page from '../components/Pages';
import { Helmet } from 'react-helmet';

function HomePage() {
    return (
            <Page  title={'Accueil'}>
                <Helmet>
                <meta
                    name="author" 
                    content="Kodinart"
                    />
                    <meta
                    property=""
                    name="description" 
                    content="Kodinart est une agence moderne de création digitale spécialisée dans le domaine des 
                    tests logiciels, dans le développement web et mobile, et dans le UI/UX design. 
                    Nous sommes la première agence des tests logiciels au Togo."
                    />
                    <meta
                    name="keywords" 
                    content="Kodin, kodina, kodinar, test, logiciels, QA & testing, développement, logiciel, 
                    développement,mobile, UI/UX, design, agence togolaise, coding, coder, intervention sur site, 
                    réalisations, kodinart."
                    />
                    <meta property="og:title" content="Kodinart"/>
                    <meta property="og:type" content="kodinart.com"/>
                    <meta property="og:url" content="https://www.facebook.com/people/Kodinart/100093572302096/?notif_id=1687741602744143&notif_t=page_user_activity&ref=notif"/>
                    <meta property="og:image" content="assets/images/logoK.png"/>               
                </Helmet>
                <HomeHero/>
                <HomeAbout/>
                <ServiceSection/>
                <PrjectsNumber/>
                <FolioOverview/>
                <BlogOverview/>
                <TestimonySwipSection/>
                <LittleContactSection/>
            </Page>
    );
}

export default HomePage;