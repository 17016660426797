
import { CommentModel } from "../../../models/CommentModel";
import * as firestore from "../index";


const commentsRef = firestore.collection(firestore.firestore, "comments");


export const addComment = async (member: CommentModel): Promise<string> => {
    return (await firestore.addDoc(commentsRef, member)).id;
};

export const getComments = async (): Promise<Array<CommentModel>> => {
    return (await firestore.getDocs(commentsRef)).docs.map(doc => doc.data() as CommentModel)
};

export const deleteComments = async (id:string): Promise<void> => {
    return (await firestore.deleteDoc(firestore.doc(commentsRef, id)));
};