import { Grid, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { BlogOverviewContaintStyle } from './BlogOverview';
import TypewriterComponent, { TypewriterClass } from 'typewriter-effect';
import ButtonStyled from '../../components/ButtonStyled';
import { USER_PAGES } from '../../routes/path';

const LittleContactSectionStyle = styled("section")(()=> ({
    minHeight:"25vh",
    // width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:"Black",
    // borderBottom:"1px solid #737180"
}))
const LittleContactSectionText = styled(Typography)(({theme})=> ({
    fontFamily:"PoppinsBold, sans-serif !important",
    fontWeight:800,
    gap:5,
    // fontSize:"2.8em",
    fontSize:"2.5em",
    color:"white",
    display:"flow",
    // fontSize:"2.8em",
    // fontWeight:"bold",
    // fontFamily:"PoppinsBold, sans-serif !important",
    '& .decorateText': {
        // fontSize:"em",
        fontSize:"1.0em",
        color:"transparent",
        WebkitTextStroke:"1px white",
        WebkitTextFillColor:"transparent",
        fontFamily:"PoppinsBold, sans-serif !important",
        // color:"transparent",
        // WebkitTextStroke:"2px #ffff",
        // WebkitTextFillColor:"transparent",
        // fontFamily:"PoppinsBold, sans-serif !important",
        // fontSize:"2.8em",
        [theme.breakpoints.down('md')]: {
            fontSize:"30px",
            textAlign:"center",

            },

      },    
      [theme.breakpoints.down('md')]: {
        textAlign:"center",
        fontSize:"30px",
        },
}))
const LittleContactSectionTextAnim = styled(Typography)(()=> ({

}))
export const LittleContactContaintStyle = styled("div")(()=> ({
    minHeight:"100%",
    width:"70vw",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"8vh 0vh 8vh 0vh ",

    // backgroundColor:"blue"
}))

const heroTextAnim = (typewriter: TypewriterClass) => {
    typewriter.typeString("Une idée de projet Web?")
    .pauseFor(2000)
    .deleteAll()
    .typeString("ou de projet en design?")
    .pauseFor(2000)
    .deleteAll()
    .start();
}

const GridStyle = styled(Grid)(({theme})=> ({

    [theme.breakpoints.down('md')]: {
        display:"grid", 
        placeItems:"center",
        margin:"0px 0px 50px 0px"
        },


}))

function LittleContactSection() {
    return (
        <LittleContactSectionStyle>
            <LittleContactContaintStyle>
                {/* <Stack direction={"row"} spacing={2}> */}
                {/* <LittleContactSectionText>
                    Avez vous une idéé de
                </LittleContactSectionText> */}
               <Grid container>
                <GridStyle item xs={12} md={10} sm={12} lg={10} sx={{}}>
                    {/* <LittleContactSectionText>
                        Avez vous une idéé    <Typography className='decorateText' component={"span"}><span><TypewriterComponent onInit={heroTextAnim} options={{loop:true}}/> </span></Typography>
                    </LittleContactSectionText> */}
                    <LittleContactSectionText>
                        <TypewriterComponent onInit={heroTextAnim} options={{loop:true}}/>
                    </LittleContactSectionText>
                </GridStyle>
                <Grid item xs={12} md={2} sm={12} lg={2} sx={{display:"grid", placeItems:"center"}}>
                    <ButtonStyled content={'Contact'} path={USER_PAGES.contact}/>
                </Grid>
               </Grid>
                {/* </Stack> */}
            </LittleContactContaintStyle>
        </LittleContactSectionStyle>
    );
}

export default LittleContactSection;